import { useAwaitModalResponse } from "Providers";
import { AccessCodeModal } from "./AccessCodeModal";

type AccessCodeHookProps = () => () => Promise<boolean>;

/**
 * [HOOK] small hook to allow a response from await modal modal response
 * checks the access allowed
 * Was Easier to add it here
 * @example const verifyAccessCode = useAwaitConfirmAccessCodeModal();
 * @example const response = await verifyAccessCode();
 * @returns {boolean}
 */
export const useAwaitConfirmAccessCodeModal: AccessCodeHookProps = () => {
    const awaitConfirm = useAwaitModalResponse();

    const openAndCheckAccessCode = async () => {
        const response: any = await awaitConfirm({
            component: AccessCodeModal,
            catchOnCancel: true
        } as any);
        return !response.cancel ?? false;
    };

    return openAndCheckAccessCode;
};
