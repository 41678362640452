import React, { useState, useCallback, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import propTypes from "prop-types";
import { Modal, Icon, Segment, Dimmer, Loader } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { get } from "lodash";

import { SpringButton, errorNotification, successNotification, printReceipt, heartBeatMessage } from "TempUtils";
import { formatSwedishFinancialNumbers, gaProductionEvent, swedishPaymentMethod } from "Utils";
import { getVatAmount } from "TempUtils/PriceCalculations";
import { useDisableMap, useImperativeQuery, useMothershipMutation } from "Hooks";
import {
    FULL_SEARCH_ORDER_HISTORY,
    SEARCH_REFUND_ORDER_BY_REFERENCE_ORDER_ID,
    GET_COMPANY_RIGHTS
} from "GraphQLQueries";
import { CREATE_RECEIPT_COPY, REFUND_CLOUD_POS_ORDER, REFUND_POS_ORDER, RETRY_FAILED_ORDER } from "GraphQLMutations";
import {
    modalNames,
    errorConstants,
    disableButtonsMap,
    ReceiptType,
    PaymentMethod,
    PosTypes,
    BaxiTerminalAction,
    BaxiTerminalStates,
    ONLINE_SERVICES
} from "Constants";
import { ReceiptForm } from "./components/ReceiptForm";
import { PastReturns } from "./components/PastReturns";
import { ReceiptModalTable } from "./components/ReceiptModalTable";
import { RefundButton } from "./components/RefundButton";
import { ProductsList } from "./components/ProductsList";
import {
    createAlreadyRefundedHash,
    getActivePage,
    getModalHeader,
    hashBundleOrderProduct,
    hashMenuProduct,
    refundAllButtonDisabled,
    selectAllForRefund,
    updatedRefundProductsAndSum,
    getTotalPrice
} from "./utils";
import "./index.scss";
import { useQopla, useAwaitModalResponse, useBaxiSocket, useLanguage } from "Providers";
import { Flex, Header } from "Atoms";
import { SelectTerminalModal } from "../SelectTerminalModal/SelectTerminalModal";
import { isVariablePriceType } from "ProductUtils";
import { hasPaymentMethod } from "Utils";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY } from "Types";
import { makeNetsSameCardRefund } from "../../utils";

const initialRefundState = {
    refundableProducts: [],
    productsToRefund: [],
    sum: 0,
    receiptNumber: null,
    referenceOrderId: null
};

const ReceiptModal = ({ closeModal, client, modalContent }) => {
    const [terminalRefundId, setTerminalRefundId] = useState(null);
    const [ongoingRefund, setOngoingRefund] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [historyOrders, setHistoryOrders] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [previousTab, setPreviousTab] = useState(currentTab);
    const [openAccordion, setOpenAccordion] = useState(false);
    const [refunded, setAlreadyRefundedProducts] = useState({
        hashedProducts: {},
        products: []
    });

    const [refund, setRefund] = useState(initialRefundState);
    const { translate } = useLanguage();

    const [CompanyRight, setCompanyRight] = useState();
    const { disableMap, addDisabled, removeDisabled } = useDisableMap();
    const awaitConfirm = useAwaitModalResponse();

    const onSwitchIndex = useCallback(newCurrentTab => setCurrentTab(newCurrentTab), []);
    const { selectedCompany, selectedTerminal, selectedShop, selectedPos } = useQopla();
    const { apiToken, logBaxiMessage } = useBaxiSocket();

    /**
     * Mothership mutations (using fragments)
     */
    const [refundCloudOrderPosMutation] = useMothershipMutation(REFUND_CLOUD_POS_ORDER);
    const [refundOrderPosMutation] = useMothershipMutation(REFUND_POS_ORDER);
    const [retryFailedOrderMutation] = useMothershipMutation(RETRY_FAILED_ORDER);
    const [createReceiptCopyMutation] = useMothershipMutation(CREATE_RECEIPT_COPY);
    /**
     * Mothership queries (using fragments)
     */
    const searchRefundOrderByReferenceOrderId = useImperativeQuery(SEARCH_REFUND_ORDER_BY_REFERENCE_ORDER_ID);
    const searchOrderHistoryQuery = useImperativeQuery(FULL_SEARCH_ORDER_HISTORY);

    const shopHasManualCard = get(selectedShop, "settings.allowedPaymentMethods", []).includes(
        PaymentMethod.MANUAL_CARD
    );

    const currentLocationIsPos = window.location.pathname === "/admin/pos";

    const { isExpressPOS = false } = modalContent;

    useEffect(() => {
        getCompanyRight();
    }, []);

    useEffect(() => {
        const { prevTerminalStates } = modalContent;
        const terminalId = getTerminalId();
        const prevState = prevTerminalStates?.get(terminalId);

        const _terminalState = getCurrentTerminalState();

        if (prevState?.action !== _terminalState?.action) {
            if (_terminalState) {
                switch (_terminalState.action) {
                    case BaxiTerminalAction.PURCHASE_REJECTED:
                    case BaxiTerminalAction.ERROR:
                        addDisabled(disableButtonsMap.CANCEL_REFUND);
                        setTimeout(() => {
                            removeDisabled(`${disableButtonsMap.REFUND_BUTTON}${refund.referenceOrderId}`);
                            setOngoingRefund(false);
                            const terminalId = getTerminalId();
                            modalContent.resetTerminalStateById(terminalId);
                        }, 3000);
                        break;
                    case BaxiTerminalAction.PURCHASE_APPROVED:
                        // if it is samecardrefund then it is handled by REST call instead of websocket
                        if (!selectedPos?.sameCardRefund) {
                            addDisabled(disableButtonsMap.CANCEL_REFUND);
                            sendCloudRefundToServer();
                        }
                        break;
                }
            }
        }
    }, [modalContent.terminalStates]);

    const getCurrentTerminalState = () => {
        const { terminalStates: terminalStateFromProps } = modalContent;
        return terminalStateFromProps && terminalStateFromProps.get(getTerminalId());
    };

    const getTerminalId = () => {
        return terminalRefundId || selectedTerminal.terminalId;
    };

    const sendRefundToServer = async order => {
        const {
            data: { refundCloudCardPosOrder }
        } = await refundCloudOrderPosMutation({
            variables: {
                posOrderInput: order
            }
        });

        const operationSuccess = refundCloudCardPosOrder.terminalResponse.operationSuccess;
        await handleRefundResult(refundCloudCardPosOrder, operationSuccess, true);
    };

    const sendCloudRefundToServer = async () => {
        const _terminalState = getCurrentTerminalState();
        const { order } = _terminalState;

        await sendRefundToServer(order);
    };

    const transitions = useTransition(currentTab, p => p, {
        initial: null,
        from: {
            opacity: 0,
            transform: `translate3d(${(currentTab - previousTab) * 100}%, 0, 0)`
        },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: {
            opacity: 0,
            transform: `translate3d(${(previousTab - currentTab) * 50}%,0,0)`
        }
    });

    if (currentTab !== previousTab) setPreviousTab(currentTab);

    const handleSetRefundOrders = (orderProduct, operation, productIdx, refundAllQuantity = false) => {
        const { updatedProductsToRefund, updatedSum } = updatedRefundProductsAndSum(
            refund,
            orderProduct,
            operation,
            refundAllQuantity,
            productIdx
        );

        setRefund(prevRefund => ({
            ...prevRefund,
            productsToRefund: updatedProductsToRefund,
            sum: updatedSum
        }));
    };

    const getCompanyRight = async () => {
        try {
            const {
                data: { getCompanyRights }
            } = await client.query({
                query: GET_COMPANY_RIGHTS,
                fetchPolicy: "network-only",
                variables: {
                    companyId: selectedCompany.id
                }
            });
            setCompanyRight(getCompanyRights);
            return getCompanyRights;
        } catch (err) {
            console.log("err when getting companyRight by companyID");
        }
    };

    const handleShowRefund = async (receiptNumber, orderId) => {
        const { data } = await searchRefundOrderByReferenceOrderId({
            shopId: modalContent.shopId,
            referenceOrderId: orderId
        });
        const refundedOrders = data?.searchRefundOrderByReferenceOrderId;

        const hashedAlreadyRefundedProducts = createAlreadyRefundedHash(refundedOrders);
        const productsForReceiptNumber = [
            ...historyOrders
                .find(ho => ho.id === orderId)
                .orderProducts.map((op, idx) => {
                    const hashedOrderProductId = op.refProductId
                        ? hashMenuProduct(op, idx)
                        : hashBundleOrderProduct(op);
                    if (hashedAlreadyRefundedProducts[hashedOrderProductId]) {
                        return {
                            ...op,
                            quantity: op.quantity - hashedAlreadyRefundedProducts[hashedOrderProductId].quantity
                        };
                    }
                    return op;
                })
        ];

        const productsToRefund = productsForReceiptNumber.map(p => ({
            ...p,
            quantity: 0,
            totalPrice: 0
        }));

        setRefund(prefRefund => ({
            ...prefRefund,
            refundableProducts: productsForReceiptNumber,
            productsToRefund,
            receiptNumber,
            referenceOrderId: orderId
        }));
        setAlreadyRefundedProducts({
            // TODO: Please note, refundedProducts is actually an array of Orders !
            products: refundedOrders,
            hashedProducts: hashedAlreadyRefundedProducts
        });
        onSwitchIndex(2);
    };

    const handleRefundOrder = async (paymentMethod, isManualCardOrder) => {
        setOngoingRefund(true);
        const refundedOrderProducts = refund.productsToRefund
            .filter(op => op.quantity > 0)
            .map(op => {
                let totalPrice = isVariablePriceType(op.priceType) ? -op.totalPrice : -op.quantity * op.unitPrice;
                const vatAmount = getVatAmount(op.vatRate, totalPrice);
                return {
                    ...op,
                    unitPrice: -op.unitPrice,
                    totalPrice,
                    totalNetPrice: totalPrice - vatAmount
                };
            });

        let terminalId = paymentMethod === swedishPaymentMethod.CARD.engConstant ? modalContent.terminalId : null;
        if (isManualCardOrder) {
            terminalId = null;
        }

        const posOrderInput = {
            orderProducts: refundedOrderProducts,
            posId: modalContent.posId,
            terminalId,
            paymentMethodAmounts: [
                {
                    paymentMethod: paymentMethod,
                    amount: -refund.sum
                }
            ],
            referenceOrderId: refund.referenceOrderId,
            shopId: modalContent.shopId,
            manualCardPayment: isManualCardOrder
        };

        const { cloudEnabled } = selectedTerminal;
        const { posTerminals } = modalContent;
        const multipleCloudTerminals =
            posTerminals && posTerminals.length > 1 && posTerminals.every(t => t.cloudEnabled);

        const isCloudTerminal =
            (cloudEnabled || multipleCloudTerminals) &&
            paymentMethod === swedishPaymentMethod.CARD.engConstant &&
            !isManualCardOrder;

        if (isCloudTerminal) {
            if (multipleCloudTerminals) {
                const res = await awaitConfirm({
                    component: SelectTerminalModal,
                    catchOnCancel: true,
                    modalContent: { posTerminals }
                });
                if (res.cancel) {
                    removeDisabled(`${disableButtonsMap.REFUND_BUTTON}${refund.referenceOrderId}`);
                    setOngoingRefund(false);
                }

                terminalId = res.value;
                setTerminalRefundId(res.value);
            }
            if (posOrderInput.referenceOrderId === null) {
                errorNotification(`Återbetalningen misslyckades`);
                removeDisabled(`${disableButtonsMap.REFUND_BUTTON}${refund.referenceOrderId}`);
                setOngoingRefund(false);
                onSwitchIndex(currentTab - 1);
            } else {
                // check if same card refund is enforced on the POS
                if (selectedPos?.sameCardRefund) {
                    const netsRefundSuccess = await makeNetsSameCardRefund(
                        selectedTerminal.terminalId,
                        apiToken,
                        selectedHistoryOrder,
                        refund.sum,
                        translate,
                        logBaxiMessage
                    );

                    if (netsRefundSuccess) {
                        await sendRefundToServer(posOrderInput);
                    }
                    return;
                } else {
                    modalContent.makeCloudRefund(terminalId, refund.sum, posOrderInput);
                }
            }
        } else {
            try {
                const {
                    data: { refundPosOrder }
                } = await refundOrderPosMutation({
                    variables: {
                        posOrderInput
                    }
                });

                const operationSuccess =
                    paymentMethod === swedishPaymentMethod.CARD.engConstant && !isManualCardOrder
                        ? refundPosOrder.terminalResponse.operationSuccess
                        : true;

                await handleRefundResult(refundPosOrder, operationSuccess, false);
            } catch (error) {
                console.log("ERROR", error);
            }
        }
    };

    const handleRefundResult = async (refundPosOrder, operationSuccess, cloudEnabled) => {
        const failedControlUnitRequest = ((refundPosOrder.order || {}).orderControlUnitValues || {}).failedRequest;

        if (operationSuccess && !failedControlUnitRequest) {
            await successNotification(`Retur för kvitto nr #${refund.receiptNumber}`, 1400);
            printReceipt(refundPosOrder.order, modalContent.receiptPrinter, true);
            cloudEnabled ? closeModal() : closeModal(modalNames.RECEIPTMODAL);
        } else {
            let failureText;
            if (operationSuccess && failedControlUnitRequest) {
                failureText = errorConstants.CONTROL_UNIT_ERROR;
            } else {
                if (refundPosOrder.terminalResponse.errorText) {
                    failureText = refundPosOrder.terminalResponse.errorText;
                } else if (failedControlUnitRequest) {
                    failureText = errorConstants.CONTROL_UNIT_ERROR;
                }
            }
            errorNotification(`Återbetalningen misslyckades`, failureText);
            removeDisabled(`${disableButtonsMap.REFUND_BUTTON}${refund.referenceOrderId}`);
            setOngoingRefund(false);
        }
    };

    const handleRetryFailedOrder = async orderId => {
        const { ip, deviceName } = modalContent.receiptPrinter;
        try {
            const printerIsAvailable = await heartBeatMessage(ip, deviceName);

            if (!printerIsAvailable) {
                errorNotification("Skrivare ej ansluten", "Kontrollera dess anslutning");
                removeDisabled(`${disableButtonsMap.RETRY_ADD_ORDER}${orderId}`);
                return false;
            }

            const { data } = await retryFailedOrderMutation({
                variables: {
                    posId: modalContent.posId,
                    orderId
                }
            });

            if (data.retryFailedOrder.order) {
                const text = `Order nr: ${data.retryFailedOrder.order.orderNo}`;
                successNotification("Beställning lagd", 1400, text);
                await printReceipt(data.retryFailedOrder.order, modalContent.receiptPrinter);
                setTimeout(() => closeModal(modalNames.RECEIPTMODAL), 2000);
            } else {
                removeDisabled(`${disableButtonsMap.RETRY_ADD_ORDER}${orderId}`);
                errorNotification(`Gick inte att lägga beställningen`);
            }
        } catch (err) {
            console.log(`${err} when retrying failed order`);
        }
    };

    const handleSearchForOrders = async values => {
        setIsSearching(true);
        let controlUnitFailure = null;
        let receiptType = values.receiptType;

        if (values.receiptType === ReceiptType.CASH_INVOICE) {
            controlUnitFailure = true;
        } else if (values.receiptType === ReceiptType.FAILED_BUT_PAYMENT_DONE) {
            controlUnitFailure = true;
            receiptType = ReceiptType.FAILED;
        } else if (values.receiptType === ReceiptType.PURCHASE) {
            controlUnitFailure = false;
        }

        const orderTypes =
            values.orderTypes === PosTypes.POS || values.orderTypes === null
                ? null
                : values.orderTypes[0] === ONLINE_SERVICES.WEB && !currentLocationIsPos
                ? Object.keys(ONLINE_SERVICES)
                : [ONLINE_SERVICES.WEB];

        try {
            const { data } = await searchOrderHistoryQuery({
                shopId: modalContent.shopId,
                ...values,
                receiptType,
                controlUnitFailure,
                orderTypes: orderTypes
            });
            const searchOrderHistory = data.searchOrderHistory;

            if (!searchOrderHistory.length) {
                return errorNotification("Hittade inga ordrar");
            }

            if (isExpressPOS) {
                const cardPaymentOrdersOnly = searchOrderHistory.filter(order => {
                    return hasPaymentMethod(order, PaymentMethod.CARD);
                });
                if (!cardPaymentOrdersOnly?.length) {
                    return errorNotification("Hittade inga ordrar");
                }
                setHistoryOrders(cardPaymentOrdersOnly);
            } else {
                setHistoryOrders(searchOrderHistory);
            }

            return onSwitchIndex(1);
        } catch (err) {
            console.log("Error when fetching history orders", err);
        } finally {
            gaProductionEvent({
                category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
                action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
                label: "Search orders"
            });
            setIsSearching(false);
        }
    };

    const handleMakeReceiptCopy = async (orderId, btnName) => {
        const { ip, deviceName } = modalContent.receiptPrinter;
        try {
            const printerIsAvailable = await heartBeatMessage(ip, deviceName);

            if (!printerIsAvailable) {
                await errorNotification("Skrivare ej ansluten", "Kontrollera dess anslutning");
                return removeDisabled(btnName);
            }

            const {
                data: { createReceiptCopy }
            } = await createReceiptCopyMutation({
                mutation: CREATE_RECEIPT_COPY,
                variables: { orderId, posId: modalContent.posId }
            });

            if (!createReceiptCopy || createReceiptCopy.order.orderControlUnitValues.failedRequest) {
                const errorReason = !createReceiptCopy
                    ? "En kopia har redan skrivits ut"
                    : errorConstants.CONTROL_UNIT_ERROR;
                errorNotification(`Skapande av kvittokopia misslyckades`, errorReason);
                removeDisabled(`${disableButtonsMap.RECEIPT_COPY_BUTTON}${orderId}`);
            } else {
                await successNotification(
                    `Kvittokopia för order nr ${createReceiptCopy.order.orderNo} skrivs ut`,
                    1400
                );
                await printReceipt(createReceiptCopy.order, modalContent.receiptPrinter);
                return closeModal(modalNames.RECEIPTMODAL);
            }
        } catch (err) {
            console.log("ERR", err);
        }
    };

    const selectedHistoryOrder =
        historyOrders && historyOrders.find(historyOrder => historyOrder.id === refund.referenceOrderId);

    let isManualCardPayment = false;

    let orderPaymentMethod;
    if (selectedHistoryOrder) {
        orderPaymentMethod = selectedHistoryOrder.paymentMethodAmounts[0].paymentMethod;
        isManualCardPayment =
            !selectedHistoryOrder.terminalId &&
            selectedHistoryOrder.paymentMethodAmounts[0].paymentMethod == PaymentMethod.CARD;
    }

    const handleCancelPosCloudRefund = async () => {
        if (selectedTerminal.cloudEnabled) {
            modalContent.cancelCloudPurchase(selectedTerminal.terminalId);
            setTimeout(() => {
                modalContent.resetTerminalStateById(selectedTerminal.terminalId);
            }, 5050);
        } else if (terminalRefundId) {
            modalContent.cancelCloudPurchase(terminalRefundId);
            setTimeout(() => {
                modalContent.resetTerminalStateById(terminalRefundId);
            }, 5050);
        }
    };

    const refundAllDisabled =
        currentTab === 2 && refundAllButtonDisabled(refund.refundableProducts, refunded.hashedProducts);
    const displayCardNumber = selectedPos?.sameCardRefund && selectedHistoryOrder?.creditCardInfo?.cardNumber;

    const pages = [
        ({ style }) => (
            <animated.div style={style}>
                <Segment>
                    <Dimmer active={isSearching} inverted>
                        <Loader inverted size="huge" content="Söker efter kvitton..." />
                    </Dimmer>

                    <ReceiptForm
                        handleSearchForOrders={handleSearchForOrders}
                        CompanyRight={CompanyRight}
                        isExpressPOS={isExpressPOS}
                    />
                </Segment>
            </animated.div>
        ),
        ({ style }) => (
            <animated.div style={style}>
                <ReceiptModalTable
                    historyOrders={historyOrders}
                    hasPuckNo={historyOrders.some(o => !!o.puckNo)}
                    makeReceiptCopy={handleMakeReceiptCopy}
                    showRefund={handleShowRefund}
                    handleRetryFailedOrder={handleRetryFailedOrder}
                    disableMap={disableMap}
                    addDisabled={addDisabled}
                    hideCopyBtns={modalContent.hideCopyBtns || false}
                />
            </animated.div>
        ),
        ({ style }) => (
            <animated.div style={style}>
                <Dimmer.Dimmable as={Segment} dimmed={ongoingRefund}>
                    <Dimmer active={ongoingRefund} inverted>
                        <Flex>
                            <Loader>
                                <h1>Retur pågår</h1>
                                <h3>
                                    {modalContent.terminalMessages &&
                                        modalContent.terminalMessages.get(
                                            selectedTerminal.terminalId || terminalRefundId
                                        )}
                                </h3>
                                <SpringButton
                                    disabled={disableMap[disableButtonsMap.CANCEL_REFUND]}
                                    customOnClick={handleCancelPosCloudRefund}
                                    className="ui button"
                                    style={{
                                        backgroundColor: "#c91618",
                                        color: "#FFF",
                                        marginTop: "2rem"
                                    }}
                                >
                                    <span>Avbryt Retur</span>
                                </SpringButton>
                            </Loader>
                        </Flex>
                    </Dimmer>

                    <ProductsList
                        refundableProducts={refund.refundableProducts}
                        productsToRefund={refund.productsToRefund}
                        refundedHashedProducts={refunded.hashedProducts}
                        handleSetRefundOrders={handleSetRefundOrders}
                    />
                    <PastReturns
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        refundedProducts={refunded.products}
                    />
                </Dimmer.Dimmable>
            </animated.div>
        )
    ];

    return (
        <Modal size="large" open={true} closeOnDimmerClick={false}>
            <Modal.Header className="space-between">
                <h1>{getModalHeader(currentTab, refund.receiptNumber)}</h1>
                <SpringButton
                    style={{ backgroundColor: "transparent", border: "none" }}
                    customOnClick={() => {
                        const _terminalState = getCurrentTerminalState();
                        if (_terminalState && _terminalState.terminalStatus === BaxiTerminalStates.BUSY) {
                            handleCancelPosCloudRefund();
                        }
                        closeModal(modalNames.RECEIPTMODAL);
                    }}
                >
                    <Icon name="close" style={{ backgroundColor: "#da2828", color: "#FFF" }} circular />
                </SpringButton>
            </Modal.Header>
            <Modal.Content scrolling className="receipt-modal-content">
                {getActivePage(transitions, pages)}
            </Modal.Content>
            <Modal.Actions className="receipt-modal-actions">
                {currentTab === 2 && (
                    <Flex justifyContent={displayCardNumber ? "space-between" : "flex-end"} mb="1rem">
                        {displayCardNumber && (
                            <Header size="lg" mb="0">
                                {translate("card")}: {selectedHistoryOrder?.creditCardInfo?.cardNumber}
                            </Header>
                        )}
                        <Header size="lg" mt="0">
                            Summa tillbaka: {formatSwedishFinancialNumbers(refund.sum)}
                        </Header>
                    </Flex>
                )}
                <SpringButton
                    className="ui button big back"
                    customOnClick={() => {
                        if (previousTab === 2) setRefund(initialRefundState);
                        onSwitchIndex(currentTab - 1);
                    }}
                    disabled={currentTab === 0}
                >
                    Gå tillbaka
                </SpringButton>
                {currentTab === 2 && (
                    <>
                        <SpringButton
                            disabled={refundAllDisabled}
                            className="ui button big select-all"
                            customOnClick={() =>
                                selectAllForRefund(
                                    refund.refundableProducts,
                                    refunded.hashedProducts,
                                    handleSetRefundOrders
                                )
                            }
                        >
                            Välj alla produkter
                        </SpringButton>
                        <RefundButton
                            orderPaymentMethod={orderPaymentMethod}
                            refund={refund}
                            disableMap={disableMap}
                            isManualCardPayment={isManualCardPayment}
                            addDisabled={addDisabled}
                            handleRefundOrder={handleRefundOrder}
                            isRefundForExpressPOS={isExpressPOS}
                        />
                    </>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export const EnhancedReceiptModal = withApollo(ReceiptModal);

ReceiptModal.propTypes = {
    activeModals: propTypes.array,
    closeAllModals: propTypes.func,
    closeModal: propTypes.func,
    modalContent: propTypes.object,
    openModal: propTypes.func
};
