import React from "react";
import { IoIosCloseCircle } from "@react-icons/all-files/io/IoIosCloseCircle";

import { SpringButton } from "TempUtils";
import { Box } from "Atoms";

type Props = {
    closeModal: Function;
};

export const PosModalCloseBtn: React.FC<Props> = ({ closeModal }) => (
    <SpringButton style={{ backgroundColor: "transparent", border: "none" }} customOnClick={closeModal}>
        <Box size="75px" color="red.700" as={IoIosCloseCircle} />
    </SpringButton>
);
