import React from "react";

import { Button, Header, Icon, Flex } from "Atoms";
import { Modal, ModalBody, ModalHeader, PosButton } from "Molecules";

type Props = {
    open: boolean;
    onSubmit: (value: { value: string | null; cancel: boolean }) => void;
    modalContent: {
        posTerminals: POSTerminals[];
    };
};

type POSTerminals = {
    terminalId: string;
    name: string;
    description: string;
};

export const SelectTerminalModal: React.FC<Props> = ({ open, onSubmit, modalContent }) => {
    const { posTerminals } = modalContent;

    const terminals = posTerminals.map(terminal => {
        return (
            <PosButton
                mx={4}
                onClick={() => onSubmit({ value: terminal.terminalId, cancel: false })}
                key={terminal.terminalId}
                header={terminal.name}
                description={terminal.description}
            />
        );
    });

    return (
        <Modal open={open}>
            <ModalHeader>
                <Flex justify="space-between" width="100%">
                    <Header as="h1" size="xl" margin="0">
                        Välj Terminal
                    </Header>
                    <Icon name="FaTimes" color="red" onClick={() => onSubmit({ value: null, cancel: true })} />
                </Flex>
            </ModalHeader>
            <ModalBody overflowY="auto" padding="2rem 1.5rem 1.5rem">
                <Flex direction="column">
                    <Flex direction="row" justifyContent="center">
                        {terminals}
                    </Flex>
                    <Button
                        size="lg"
                        themeColor="red"
                        mt={12}
                        onClick={() => {
                            onSubmit({ value: null, cancel: true });
                        }}
                    >
                        Avbryt
                    </Button>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
