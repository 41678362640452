import React from "react";
import propTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import ReactTable from "react-table";
import { useMutation } from "@apollo/react-hooks";
import "react-table/react-table.css";

import { Divider, Flex, Header, Text, Box } from "Atoms";
import { SpringButton, formatSwedishFinancialNumbers, formatDateToLocal, ServiceIconForOrderHistory } from "TempUtils";
import {
    disableButtonsMap,
    ReceiptType,
    PosTypes,
    onlineOrderStatusToSwedish,
    refundStatusConstants,
    orderTypes,
    onlineOrderStatus,
    modalNames,
    thirdPartyDeliveryNames,
    PaymentMethod
} from "Constants";
import { AddonsCartItem } from "../../../../../poses/shared/components";
import { swedishReactTableProperties } from "../../../../../../utils/TextFormat";
import {
    REPRINT_KITCHEN_ORDER_BY_ORDER_ID,
    RESEND_CATERING_ORDER_CONFIRMATION_EMAIL,
    RESEND_ORDER_CONFIRMATION_EMAIL
} from "GraphQLMutations";
import { useQopla } from "Providers";
import { isVariablePriceType } from "ProductUtils";
import { getUnitStringForPriceType, roundPriceForPriceType } from "PriceUtils";
import { isCatering, swedishPaymentMethod } from "Utils";
import { useModalStore } from "Stores";
import { EatingOption, WebOrderType } from "Types";

const renderModificationsArray = modifications => {
    const flavoursExists = modifications.flavours && modifications.flavours.length > 0;
    const sizesExists = modifications.sizes && modifications.sizes.length > 0;
    if (!flavoursExists && !sizesExists) return null;
    return (
        <ul
            className="no-margin"
            style={{
                listStyleType: "none",
                paddingLeft: "11px",
                paddingTop: "0.5em"
            }}
        >
            {flavoursExists && <li style={{ color: "grey" }}>{modifications.flavours[0].name}</li>}
            {sizesExists && <li style={{ color: "grey" }}>{modifications.sizes[0].name}</li>}
        </ul>
    );
};

const statusColor = status => {
    switch (onlineOrderStatusToSwedish[status]) {
        case onlineOrderStatusToSwedish.CONFIRMED:
            return "green";
        case onlineOrderStatusToSwedish.DENIED:
        case onlineOrderStatusToSwedish.CLIENT_AUTO_DENIED:
        case onlineOrderStatusToSwedish.SHOP_AUTO_DENIED:
        case onlineOrderStatusToSwedish.OFFLINE_AUTO_DENIED:
            return "red";
        default:
            return "yellow";
    }
};

const renderBundleProductItems = bundleProductItems =>
    bundleProductItems.map((bundleItem, i) => (
        <React.Fragment key={i}>
            <li>{bundleItem.name}</li>
            {bundleItem.modifications && renderModificationsArray(bundleItem.modifications)}
            {bundleItem.addons && <AddonsCartItem hidePrice addons={bundleItem.addons} />}
        </React.Fragment>
    ));

export const renderOrderProducts = orderProducts =>
    orderProducts.map((orderProduct, i) => {
        const isDiscounted = !!orderProduct.discountValue;
        const hasVariablePriceType =
            orderProduct.priceType !== null ? isVariablePriceType(orderProduct.priceType) : false;
        const totalPrice =
            orderProduct.totalPrice || orderProduct.totalPrice == 0
                ? orderProduct.totalPrice
                : orderProduct.price * orderProduct.quantity;
        const totalPriceString = formatSwedishFinancialNumbers(totalPrice);

        const unitString = hasVariablePriceType ? getUnitStringForPriceType(orderProduct.priceType) : "styck";
        const quantityOrWeightLabel = hasVariablePriceType
            ? `${orderProduct.weight} ${unitString} ${orderProduct.name} | `
            : `${orderProduct.quantity} st ${orderProduct.name} | `;

        const perUnitPrice =
            hasVariablePriceType &&
            `(${roundPriceForPriceType(totalPrice / orderProduct.weight, orderProduct.priceType)}kr / ${unitString})`;

        return (
            <li key={orderProduct.name + i}>
                <span>
                    {quantityOrWeightLabel}
                    <strong>
                        {totalPriceString} {perUnitPrice}
                    </strong>
                    {isDiscounted && <Icon name="tag" color="red" />}
                </span>
                <div>
                    {orderProduct.selectedBundleProductItems && (
                        <ul style={{ paddingLeft: "11px" }}>
                            {renderBundleProductItems(orderProduct.selectedBundleProductItems)}
                        </ul>
                    )}
                    {orderProduct.modifications && renderModificationsArray(orderProduct.modifications)}
                    {orderProduct.addons && (
                        <AddonsCartItem
                            hidePrice={true}
                            className="refund-addon-display"
                            addons={orderProduct.addons}
                        />
                    )}
                </div>
                {i < orderProducts.length - 1 && <Divider margin={"10px 0px"} />}
            </li>
        );
    });

export const ReceiptModalTable = ({
    historyOrders,
    disableMap,
    hasPuckNo,
    hideCopyBtns,
    addDisabled,
    makeReceiptCopy,
    handleRetryFailedOrder,
    showRefund
}) => {
    const [printKitchenOrder] = useMutation(REPRINT_KITCHEN_ORDER_BY_ORDER_ID);
    const [resendCateringOrderConfirmationEmail] = useMutation(RESEND_CATERING_ORDER_CONFIRMATION_EMAIL);
    const [resendOrderConfirmationEmail] = useMutation(RESEND_ORDER_CONFIRMATION_EMAIL);

    const { selectedShop } = useQopla();
    const { openModal } = useModalStore();

    const receiptType = historyOrders && historyOrders[0].receiptType;
    const orderType = historyOrders && historyOrders[0].orderType;
    const isPosOrOnline = orderType === PosTypes.POS || orderType === orderTypes.WEB;

    const failed = receiptType === ReceiptType.FAILED;
    const refund = receiptType === ReceiptType.REFUND;

    const settings = selectedShop?.settings;
    const hasPrinterEnabled = settings?.enableKitchenPrinterOnline || settings?.enableKitchenPrinterPOS;
    const onSendReceiptCopy = order => {
        const mutation = isCatering(order.eatingOption)
            ? resendCateringOrderConfirmationEmail
            : resendOrderConfirmationEmail;

        openModal(modalNames.RESEND_CONFIRMATION_EMAIL_MODAL, {
            mutation,
            orderId: order.id,
            orderEmail: "",
            openFrom: "backOffice"
        });
    };

    const isOnlineOrder = orderType => {
        return orderType === orderTypes.WEB || thirdPartyDeliveryNames[orderType] ? true : false;
    };
    return (
        historyOrders.length > 0 && (
            <ReactTable
                data={historyOrders}
                className="-striped -highlight"
                {...swedishReactTableProperties}
                columns={[
                    { Header: "Kvitto nr.", accessor: "receiptNumber", width: orderType === orderTypes.WEB ? 38 : 72 },
                    { Header: "Order nr.", accessor: "orderNo", width: orderType === orderTypes.WEB ? 38 : 72 },
                    {
                        Header: "Tjänst",
                        id: "thirdPartyService",
                        width: 40,
                        accessor: p => <Box textAlign="center">{ServiceIconForOrderHistory(p.orderType)}</Box>
                    },
                    {
                        Header: "Puck nr.",
                        accessor: "puckNo",
                        show: hasPuckNo
                    },
                    {
                        Header: "Kontaktinfo",
                        id: "orderType",
                        show: isOnlineOrder(orderType),
                        accessor: order => {
                            const onlineContactInformation = _.get(
                                order,
                                "userInformation.onlineContactInformation",
                                {}
                            );
                            const isHomeDelivery =
                                order.eatingOption === EatingOption.HOME_DELIVERY ||
                                order.webOrderType === WebOrderType.CATERING_DELIVERY;
                            return (
                                <Flex flexDirection="column">
                                    {!thirdPartyDeliveryNames[order.orderType] && (
                                        <>
                                            <Header as="h4" size="tiny" margin="0">
                                                Telefon
                                            </Header>
                                            <Text margin="0">{onlineContactInformation.phoneNumber}</Text>
                                            {isHomeDelivery && (
                                                <>
                                                    <Header as="h4" size="tiny" margin="0">
                                                        Adress
                                                    </Header>
                                                    <Text margin="0">
                                                        {onlineContactInformation.addressLine},{" "}
                                                        {onlineContactInformation.postCode}
                                                    </Text>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Flex>
                            );
                        }
                    },
                    {
                        Header: "Produkter",
                        id: "orderProducts",
                        width: isOnlineOrder(orderType) ? 260 : 350,
                        accessor: p => (
                            <ul style={{ paddingLeft: "20px", marginTop: 0 }}>
                                {renderOrderProducts(p.orderProducts)}
                            </ul>
                        )
                    },
                    {
                        Header: "Belopp",
                        id: "totalAmount",
                        width: 80,
                        accessor: t => formatSwedishFinancialNumbers(t.totalAmount)
                    },
                    {
                        Header: "Betalningssätt",
                        id: "paymentMethodAmounts",
                        width: 110,
                        accessor: order =>
                            order.paymentMethodAmounts &&
                            order.paymentMethodAmounts.map((payment, i) => {
                                const isManualCardPayment =
                                    !order.terminalId &&
                                    !order.creditCardInfo &&
                                    payment.paymentMethod == PaymentMethod.CARD;
                                const paymentMethod = isManualCardPayment
                                    ? PaymentMethod.MANUAL_CARD
                                    : payment.paymentMethod;
                                return (
                                    <Box as="p" whiteSpace={"break-spaces"} key={i}>
                                        {swedishPaymentMethod[paymentMethod].swe}
                                    </Box>
                                );
                            })
                    },
                    {
                        Header: "Datum",
                        id: "purchaseDate",
                        width: 125,
                        accessor: d => formatDateToLocal(d.purchaseDate)
                    },
                    {
                        Header: "Status",
                        id: "onlineOrderStatus",
                        width: 90,
                        show: isOnlineOrder(orderType),
                        accessor: d => (
                            <div>
                                <Icon name="circle" color={statusColor(d.onlineOrderStatus)} />
                                {onlineOrderStatusToSwedish[d.onlineOrderStatus]}
                            </div>
                        )
                    },
                    {
                        Header: "",
                        id: "buttons",
                        accessor: d => {
                            const canShowCopyBtns = !hideCopyBtns;
                            const isNotWebOrder = orderType !== orderTypes.WEB;
                            const isWebOrder = isOnlineOrder(orderType);

                            const showRefundButton = canShowCopyBtns && isNotWebOrder && !refund;
                            const showResendButton = !failed && canShowCopyBtns && isPosOrOnline;

                            const isOrderConfirmed = !!(
                                !d.onlineOrderStatus || d.onlineOrderStatus === onlineOrderStatus.CONFIRMED
                            );

                            const isFailed = d.receiptType === ReceiptType.FAILED;
                            const isCashInvoice = d.receiptType === ReceiptType.CASH_INVOICE;
                            const isFullyRefunded = d.refundStatus === refundStatusConstants.FULLY_REFUNDED;

                            return (
                                <Flex direction="column" align="center">
                                    {showResendButton && !isCashInvoice && (
                                        <>
                                            <SpringButton
                                                style={{ marginBottom: "0.75rem", width: "100%" }}
                                                className="ui tiny blue button"
                                                disabled={
                                                    disableMap[`${disableButtonsMap.RECEIPT_COPY_BUTTON}${d.id}`] ||
                                                    d.hasCopy
                                                }
                                                customOnClick={() => {
                                                    addDisabled(`${disableButtonsMap.RECEIPT_COPY_BUTTON}${d.id}`);
                                                    makeReceiptCopy(d.id);
                                                }}
                                            >
                                                Kvittokopia-Skriv ut
                                            </SpringButton>
                                        </>
                                    )}
                                    {hideCopyBtns && d.orderType === orderTypes.WEB && (
                                        <SpringButton
                                            style={{ marginBottom: "0.75rem", width: "100%" }}
                                            className="ui tiny blue button"
                                            customOnClick={() => {
                                                onSendReceiptCopy(d);
                                            }}
                                        >
                                            Kvittokopia-mail
                                        </SpringButton>
                                    )}
                                    {hasPrinterEnabled && isOrderConfirmed && !thirdPartyDeliveryNames[d.orderType] && (
                                        <SpringButton
                                            style={{ marginBottom: "0.75rem", width: "100%" }}
                                            className="ui tiny blue button"
                                            customOnClick={async () => {
                                                printKitchenOrder({ variables: { orderId: d.id } });
                                            }}
                                        >
                                            Skriv ut bong
                                        </SpringButton>
                                    )}
                                    {showRefundButton && (
                                        <>
                                            {isFailed ? (
                                                <SpringButton
                                                    style={{ marginBottom: "0.75rem", width: "100%" }}
                                                    className="ui tiny button green-button"
                                                    disabled={disableMap[`${disableButtonsMap.RETRY_ADD_ORDER}${d.id}`]}
                                                    customOnClick={() => {
                                                        addDisabled(`${disableButtonsMap.RETRY_ADD_ORDER}${d.id}`);
                                                        handleRetryFailedOrder(d.id);
                                                    }}
                                                >
                                                    Lägg beställning
                                                </SpringButton>
                                            ) : (
                                                <SpringButton
                                                    style={{ width: "100%" }}
                                                    disabled={isFullyRefunded || isWebOrder}
                                                    className="ui tiny red button"
                                                    customOnClick={() => showRefund(d.receiptNumber, d.id)}
                                                >
                                                    Retur
                                                </SpringButton>
                                            )}
                                        </>
                                    )}
                                </Flex>
                            );
                        }
                    }
                ]}
            />
        )
    );
};

ReceiptModalTable.propTypes = {
    historyOrders: propTypes.array,
    makeReceiptCopy: propTypes.func
};
