import React, { useState } from "react";
import { FaRegEdit } from "@react-icons/all-files/fa/FaRegEdit";
import { BsLayoutSplit } from "@react-icons/all-files/bs/BsLayoutSplit";
import { BiCalculator } from "@react-icons/all-files/bi/BiCalculator";
import { MdClear } from "@react-icons/all-files/md/MdClear";
import { RiRecycleFill } from "@react-icons/all-files/ri/RiRecycleFill";
import { MdPlaylistAdd } from "@react-icons/all-files/md/MdPlaylistAdd";
import { MdPlaylistAddCheck } from "@react-icons/all-files/md/MdPlaylistAddCheck";

import { SpringButton } from "TempUtils";
import {
    useAwaitModalResponse,
    useSplitCashRegister,
    usePos,
    useWasteProduct,
    modals,
    useDiscounts,
    useLocalPosSettings
} from "Providers";
import { getCartTotalOriginalPrice, getCartTotalPrice } from "../../../utils";
import { getCartTotalNetPrice } from "../../../utils/priceUtils";
import {
    flatProductsFromCategories,
    removeLastChar,
    cartContainsAtLeastOneBundleProduct
} from "Utils";
import { PLU_AND_DELETE_BUTTONS, POS_MODE } from "Constants";
import { Flex, Header, Box, Button, Switch, FormLabel, Keypad, KeypadButtons, KeypadButton, KeypadInput } from "Atoms";
import { PuckModal } from "../../PuckModal/PuckModal";
import { Menu, OrderWindow, StringOrNumber } from "Types";
import { PosCartReceipt } from "./components";
import { getDiscountText, getDiscountTotal, getComboDiscountsIdAndValue } from "./posCartFooterUtils";
import { useOrderWindowsStore, useModalStore, useTableStore, usePosStore, useQoplaStore } from "Stores";

type Props = {
    menus: Menu[];
    addOrderProductToCart: any;
    updateOrderWindowPuckNo: (orderWindowId: string, puckNo: string) => void;
    activePane: number;
};

type PosCartFooterTakeawaySwitchProps = {
    takeAway: boolean;
    orderWindowId: string;
};
const PosCartFooterTakeawaySwitch: React.FC<PosCartFooterTakeawaySwitchProps> = React.memo(
    ({ takeAway, orderWindowId }) => {
        const { toggleTakeAway } = useOrderWindowsStore();
        return (
            <Flex
                align="center"
                alignItems="center"
                flexDirection={{ base: "column", "2xl": "row" }}
                padding={{ base: 2, xl: 0 }}
            >
                <Switch
                    size="touch"
                    themeColor="green"
                    mr={{ base: 0, xl: 4 }}
                    isChecked={takeAway}
                    onChange={() => {
                        toggleTakeAway(orderWindowId);
                    }}
                />
                <FormLabel fontSize="xl" margin={0} p={0} color="gray.700">
                    Take away
                </FormLabel>
            </Flex>
        );
    },
    (prevProps, nextProps) =>
        prevProps.takeAway == nextProps.takeAway && prevProps.orderWindowId == nextProps.orderWindowId
);

export const PosCartFooter: React.FC<Props> = ({
    menus,
    addOrderProductToCart,
    updateOrderWindowPuckNo,
    activePane
}) => {
    const { getActiveOrderWindow, emptyCart, productAmount, setProductAmount, addBundleProductToCart } =
        useOrderWindowsStore();
    const { queueHasStarted, handleQueueOrders } = usePosStore();
    const orderWindow = getActiveOrderWindow();
    const {
        cartProducts,
        discount,
        takeAway,
        id: orderWindowId,
        puckNo,
        postponeOrderId,
        comboDiscounts
    } = orderWindow || ({} as OrderWindow);

    const [showNumPad, setShowNumPad] = useState(false);
    const [foundProduct, setFoundProduct] = useState(false);

    const { selectedPos } = usePosStore();
    const { selectedShop } = useQoplaStore();

    const isDriveThrough = selectedPos?.postponePaymentEnabled;
    const isWasteOrdersEnabled = selectedPos?.wasteOrderEnabled;
    const shopId = selectedShop?.id;

    const { openModal } = useModalStore();
    const awaitConfirm = useAwaitModalResponse();
    const { getOrderWindow, activeOrderWindowId, productCardAction } = usePos();
    const {
        switchCombinedDiscountState: { comboDiscountState, setComboDiscountState }
    } = useDiscounts();
    const { posMode, addSplitModeWindow } = useSplitCashRegister();
    const { registerWaste } = useWasteProduct();
    const {
        posSettings: { enableOrderQueue },
        disableOnLockingPos: isCustomerFacingEnabled
    } = useLocalPosSettings();
    const tableService = useTableStore();

    const openModModal = (product: any, modificationsToUse: any) => {
        openModal(modals.posModificationsModal, {
            productName: product.refProduct.name,
            modificationsToUse,
            modalCallback: (selectedModifications: any, menuProduct = product) =>
                addOrderProductToCart(
                    menuProduct,
                    shopId,
                    menuProduct.menuCategoryId, // retrieved through the flatmap
                    selectedModifications,
                    true
                )
        });
    };

    const articleNumberSearch =
        (query: any) =>
            ({ refProduct, refBundleProduct }: any) => {
                return (refProduct || refBundleProduct).articleNumber == query;
            };

    const searchProduct = (hasBeenSearched: boolean) => {
        const activeMenu = menus[activePane];
        const flattenProducts = flatProductsFromCategories(activeMenu.menuProductCategories);

        const found = flattenProducts.find(articleNumberSearch(productAmount));

        if (!found) return setFoundProduct(true);

        productCardAction(
            found,
            { addBundleProductToCart, addOrderProductToCart },
            shopId!,
            //@ts-ignore
            found.menuCategoryId,
            openModModal,
            hasBeenSearched,
            null as any,
            null as any
        );

        setFoundProduct(false);
        setProductAmount("");
    };

    const total = getCartTotalPrice(cartProducts);
    const totalNetPrice = getCartTotalNetPrice(cartProducts);
    const vatAmount = total - totalNetPrice;

    const discountNamesAndValues =
        comboDiscounts && comboDiscounts?.length > 0 && getComboDiscountsIdAndValue(cartProducts, comboDiscounts);
    const hasComboDiscounts = !!discountNamesAndValues || !!(comboDiscounts && comboDiscounts.length > 0);
    const hasAppliedDiscount = !!discount;

    const originalTotal = hasComboDiscounts || hasAppliedDiscount ? getCartTotalOriginalPrice(cartProducts) : 0;
    const discountTotal = discount ? getDiscountTotal(cartProducts, discount.id) : 0;
    const hasAppliedDiscountTotal = discountTotal !== 0;

    const discountAmountOrRate = discount ? getDiscountText(discount) : "";

    if (hasAppliedDiscountTotal && hasComboDiscounts) {
        discountNamesAndValues &&
            discountNamesAndValues.set(`${discount.name} (${discountAmountOrRate})`, discountTotal);
    }

    const totalComboDiscountValue = discountNamesAndValues
        ? Array.from(discountNamesAndValues).reduce((value: number, nameValue: [string, number]) => {
            return (value += nameValue[1]);
        }, 0)
        : 0;

    const isNotDriveThoughOrHasTable = !isDriveThrough || tableService.selectedTable;
    const shouldShowTakeawaySwitch = isNotDriveThoughOrHasTable;
    const shouldShowSplitButton = isNotDriveThoughOrHasTable && !isCustomerFacingEnabled;
    const hasSelectedTable = !!tableService.selectedTable?.id;
    const shouldShowQueueButton = !isDriveThrough && enableOrderQueue && !hasSelectedTable && !isCustomerFacingEnabled;
    const shouldShowWasteButton = isWasteOrdersEnabled && !isCustomerFacingEnabled;

    if (tableService.isInTableSelectionMode) {
        return (
            <PosCartReceipt
                hasAppliedDiscount={hasAppliedDiscount}
                hasComboDiscounts={hasComboDiscounts}
                hasAppliedDiscountTotal={hasAppliedDiscount}
                discountNamesAndValues={discountNamesAndValues}
                comboDiscounts={comboDiscounts}
                discount={discount}
                discountAmountOrRate={discountAmountOrRate}
                originalTotal={originalTotal}
                totalComboDiscountValue={totalComboDiscountValue}
                vatAmount={vatAmount}
                totalAmount={total}
                discountTotal={discountTotal}
            />
        );
    } else {
        return (
            <>
                <Keypad
                    onChange={(value: StringOrNumber) => setProductAmount(String(value))}
                    value={productAmount}
                    customHandlers={{
                        PLU: () => {
                            searchProduct(true);
                            setProductAmount("");
                        },
                        DEL: (value: string) => {
                            setProductAmount(removeLastChar(value));
                        }
                    }}
                    isOpen={showNumPad}
                    onToggleKeyPad={() => setShowNumPad(curr => !curr)}
                    closeOnOutsideClick={false}
                    hideInputWhenClosed
                >
                    {/* Couldn't close Keypad when pressing Calculation button. Ugly hack to be able to close it for now */}
                    <KeypadInput size="lg" rounded="0" isInvalid={foundProduct} fullWidth onFocus={() => { }} />
                    <KeypadButtons>
                        {PLU_AND_DELETE_BUTTONS.map(button => (
                            <KeypadButton key={button.value} {...button} />
                        ))}
                    </KeypadButtons>
                </Keypad>
                <div className="np-cart-footer-container">
                    {posMode === POS_MODE.REGULAR && (
                        <>
                            <Flex direction="column" align="flex-end">
                                <Flex
                                    width="100%"
                                    align="flex-end"
                                    justify={isDriveThrough ? "flex-end" : "space-between"}
                                >
                                    {shouldShowTakeawaySwitch && (
                                        <PosCartFooterTakeawaySwitch
                                            takeAway={takeAway}
                                            orderWindowId={orderWindowId}
                                        />
                                    )}
                                    <Flex flex="1" align="center" wrap="wrap" justify="flex-end">
                                        <Flex>
                                            {shouldShowQueueButton && (
                                                <SpringButton
                                                    className={
                                                        queueHasStarted
                                                            ? "np-cart-stop-queue-btn"
                                                            : "np-cart-start-queue-btn"
                                                    }
                                                    customOnClick={() => handleQueueOrders()}
                                                    disabled={!!postponeOrderId}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Box
                                                        as={queueHasStarted ? MdPlaylistAddCheck : MdPlaylistAdd}
                                                        color="white"
                                                        size="28px"
                                                    />
                                                </SpringButton>
                                            )}
                                            {!isCustomerFacingEnabled && (
                                                <SpringButton
                                                    className="np-num-pad-btn"
                                                    customOnClick={() => setShowNumPad(prev => !prev)}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Box as={BiCalculator} color="white" size="28px" />
                                                </SpringButton>
                                            )}
                                            <SpringButton
                                                className="np-cart-clear-btn"
                                                customOnClick={() => emptyCart(orderWindowId, false)}
                                                disabled={cartProducts.length === 0 || !!postponeOrderId}
                                                style={{ display: "flex" }}
                                            >
                                                <Box as={MdClear} color="white" size="28px" />
                                            </SpringButton>
                                        </Flex>

                                        <Flex>
                                            {shouldShowSplitButton && (
                                                <SpringButton
                                                    className="np-cart-split-btn"
                                                    customOnClick={() =>
                                                        addSplitModeWindow(getOrderWindow(activeOrderWindowId))
                                                    }
                                                    disabled={cartProducts.length === 0 || queueHasStarted}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Box as={BsLayoutSplit} color="white" size="28px" />
                                                </SpringButton>
                                            )}
                                            {shouldShowWasteButton && (
                                                <SpringButton
                                                    className="np-waste-btn"
                                                    customOnClick={() => registerWaste(orderWindowId, cartProducts)}
                                                    disabled={cartContainsAtLeastOneBundleProduct(cartProducts)}
                                                    style={{ display: "flex" }}
                                                >
                                                    <Box as={RiRecycleFill} color="white" size="28px" />
                                                </SpringButton>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                            {puckNo && (
                                <Flex justify="space-between" align="center" pt={4}>
                                    <Header as="h4" size="md" m={0}>
                                        Puck nr.
                                    </Header>
                                    <Flex align="center">
                                        <Header as="h4" size="md" mb={0} mr={2}>
                                            {puckNo}
                                        </Header>
                                        <Button
                                            p={0}
                                            rounded="md"
                                            size="lg"
                                            onClick={async () => {
                                                const res: any = await awaitConfirm({
                                                    component: PuckModal,
                                                    catchOnCancel: true,
                                                    modalContent: { puckNo }
                                                } as any);

                                                if (!res.cancel) {
                                                    updateOrderWindowPuckNo(orderWindowId, res.value);
                                                }
                                            }}
                                        >
                                            <Box as={FaRegEdit} />
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}
                        </>
                    )}
                    <PosCartReceipt
                        hasAppliedDiscount={hasAppliedDiscount}
                        hasComboDiscounts={hasComboDiscounts}
                        hasAppliedDiscountTotal={hasAppliedDiscount}
                        discountNamesAndValues={discountNamesAndValues}
                        comboDiscounts={comboDiscounts}
                        discount={discount}
                        discountAmountOrRate={discountAmountOrRate}
                        originalTotal={originalTotal}
                        totalComboDiscountValue={totalComboDiscountValue}
                        vatAmount={vatAmount}
                        totalAmount={total}
                        discountTotal={discountTotal}
                    />
                </div>
            </>
        );
    }
};
