import React, { useState } from "react";

import { Modal, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { useTheme, useLocalPosSettings } from "Providers";
import { Box, Header } from "Atoms";
import { useEventCallback } from "Hooks";
import { PinCode } from "./PinCode";

type Props = {
    onSubmit: (value: { value: string | null; cancel: boolean }) => void;
};

export const AccessCodeModal: React.FC<Props> = ({ onSubmit }) => {
    const { colors } = useTheme();
    const [isWrongCode, setIsWrongCode] = useState<boolean>(false);
    const {
        posSettings: { accessCode }
    } = useLocalPosSettings();

    const onCodeEntered = (codeEntered: string) => {
        if (codeEntered && codeEntered === accessCode) {
            onSubmit({ value: "", cancel: false });
        } else {
            setIsWrongCode(true);
        }
    };

    useEventCallback({
        eventName: "keydown",
        callback: (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onSubmit({ value: null, cancel: true });
            }
        }
    });

    return (
        <Modal open size="xs" placement="center">
            <ModalCloseBtn
                onClick={() => onSubmit({ value: null, cancel: true })}
                size="lg"
                style={{ backgroundColor: colors.red["500"], color: colors.white, borderRadius: "50%" }}
            />
            <ModalHeader display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Header as="h3" m="0" color="gray.800">
                    Ange Kod:
                </Header>
                {isWrongCode && (
                    <Box as="p" m="0" mt={1} color="red.500">
                        * Fel Kod
                    </Box>
                )}
            </ModalHeader>
            <ModalBody>
                <PinCode pinLength={4} onPassBackCode={(code: string) => onCodeEntered(code)} />
            </ModalBody>
        </Modal>
    );
};
