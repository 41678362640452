import React from "react";
import { BiHappy } from "@react-icons/all-files/bi/BiHappy";
import { sumBy } from "lodash";

import { Box, Header, NewList, NewListItem, Flex } from "Atoms";
import { ISplitCashRegisterContext, usePos } from "Providers";
import { PosReturnProductCard } from ".";
import { PosCartBundleItems, PosCartModifications } from "../../cart";
import { Dimmer, DimmerContent } from "Molecules";
import { AddonsCartItem } from "../../../../shared/components";

type Props = {
    splitCartProducts: ISplitCashRegisterContext["splitCartProducts"];
    addCartProductToPay: ISplitCashRegisterContext["addCartProductToPay"];
    isOrderWindowBusy: boolean;
};

export const SplitModeContent: React.FC<Props> = ({ splitCartProducts, addCartProductToPay, isOrderWindowBusy }) => {
    const { addons } = usePos();

    const { toPay, available } = splitCartProducts;

    if (toPay.length === 0 && available.length === 0) {
        return (
            <Flex justify="center" align="center" direction="column">
                <Box size="50px" as={BiHappy} />
                <Header as="h4" size="md" mt={4}>
                    Alla produkter har betalats
                </Header>
            </Flex>
        );
    } else {
        return (
            <Flex direction="column">
                <Header as="h4" size="md" textAlign="right">
                    Tillgängliga produkter: {sumBy(available, "orderProduct.quantity")} st
                </Header>
                <NewList stretch={4} flex="1 1 0" overflowY="auto">
                    {available.map(cartProduct => {
                        const { orderProduct } = cartProduct;
                        return (
                            <NewListItem key={cartProduct.id}>
                                <Dimmer isActive={isOrderWindowBusy}>
                                    <PosReturnProductCard
                                        name={orderProduct.name}
                                        price={orderProduct.totalPrice}
                                        quantity={orderProduct.quantity}
                                        handler={() => !isOrderWindowBusy && addCartProductToPay(cartProduct)}
                                    >
                                        {orderProduct.modifications && (
                                            <PosCartModifications
                                                modifications={orderProduct.modifications as any}
                                                className=""
                                            />
                                        )}
                                        {orderProduct.selectedBundleProductItems && (
                                            <PosCartBundleItems
                                                cartProduct={cartProduct}
                                                selectedBundleItems={orderProduct.selectedBundleProductItems}
                                                activeUtilItemsOpened={false}
                                                shouldDisableAllButtons={true}
                                                addons={addons}
                                                openAddonModal={() => {}}
                                            />
                                        )}
                                        {orderProduct.addons && <AddonsCartItem addons={orderProduct.addons} />}
                                    </PosReturnProductCard>
                                    <DimmerContent bg="whiteAlpha.600" />
                                </Dimmer>
                            </NewListItem>
                        );
                    })}
                </NewList>
            </Flex>
        );
    }
};
