import React, { useState } from "react";

import { InputGroup, InputRightElement, Modal, ModalBody, ModalHeader } from "Molecules";
import { Header, Icon, Flex, Button, Keypad, KeypadInput, KeypadButtons, KeypadButton, Input, Stack } from "Atoms";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "Organisms";
import { PreferredPuckTab } from "Types";

const puckButtons = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 3, children: 3 },
    { value: 4, children: 4 },
    { value: 5, children: 5 },
    { value: 6, children: 6 },
    { value: 7, children: 7 },
    { value: 8, children: 8 },
    { value: 9, children: 9 },
    { value: "Ingen Puck", children: "Ingen Puck", themeColor: "red" },
    { value: 0, children: 0 },
    { value: "Spara", children: "Spara", themeColor: "green" }
];

type Props = {
    onSubmit: (value: { value: string | null; cancel: boolean }) => void;
    modalContent: any;
};

export const PuckModal: React.FC<Props> = ({ onSubmit, modalContent }) => {
    const [puckNo, setPuckNo] = useState(modalContent.puckNo || "");

    const modalHeaderTxt = modalContent.puckNo ? `Uppdatera Puck ${modalContent.puckNo}` : "Ange Puck";
    const defaultTabIndex = modalContent.preferredPuckTab === PreferredPuckTab.NUM_PAD ? 0 : 1;

    const onNoPuckClick = () => onSubmit({ value: null, cancel: false });
    const onSaveClick = () => onSubmit({ value: puckNo, cancel: false });

    return (
        <Modal id="posPuckModal" minH="xs" open>
            <ModalHeader>
                <Flex justify="space-between" width="100%">
                    <Header as="h1" size="xl" margin="0" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {modalHeaderTxt}
                    </Header>
                    <Icon name="FaTimes" color="red" onClick={() => onSubmit({ value: null, cancel: true })} />
                </Flex>
            </ModalHeader>
            <ModalBody overflowY="auto" pt={0}>
                <Tabs isLazy defaultIndex={defaultTabIndex}>
                    <TabList mb={6}>
                        <Tab flex="1" fontSize="xl" minH={16}>
                            Siffra
                        </Tab>
                        <Tab flex="1" fontSize="xl" minH={16}>
                            Text
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Keypad
                                isDefaultOpen
                                closeOnOutsideClick={false}
                                customHandlers={{
                                    "Ingen Puck": onNoPuckClick,
                                    Spara: onSaveClick
                                }}
                                onChange={(number: any) => setPuckNo(number)}
                                value={puckNo}
                                disabledButtons={puckNo.length === 0 ? ["Spara"] : []}
                            >
                                <InputGroup w="100%" size="xl" mb={1}>
                                    <KeypadInput fullWidth />
                                    <InputRightElement p={2}>
                                        <Button h="100%" themeColor="red" onClick={() => setPuckNo("")}>
                                            C
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <KeypadButtons>
                                    {puckButtons.map(button => (
                                        <KeypadButton key={button.value} {...button} />
                                    ))}
                                </KeypadButtons>
                            </Keypad>
                        </TabPanel>
                        <TabPanel>
                            <InputGroup size="xl">
                                <Input
                                    autoFocus
                                    value={puckNo}
                                    onChange={(event: any) => setPuckNo(event.target.value)}
                                    fullWidth
                                />
                                <InputRightElement p={2}>
                                    <Button h="100%" themeColor="red" onClick={() => setPuckNo("")}>
                                        C
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Stack isInline stretch={4}>
                                <Button rounded="lg" onClick={onNoPuckClick} themeColor="red" mt={4} size="xl">
                                    Ingen puck
                                </Button>
                                <Button
                                    rounded="lg"
                                    onClick={onSaveClick}
                                    isDisabled={!puckNo}
                                    themeColor="green"
                                    fullWidth
                                    mt={4}
                                    size="xl"
                                >
                                    Spara
                                </Button>
                            </Stack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </ModalBody>
        </Modal>
    );
};
