import React, { useState } from "react";
import { HiCursorClick } from "@react-icons/all-files/hi/HiCursorClick";
import { BsFillBellFill } from "@react-icons/all-files/bs/BsFillBellFill";
import { TiCancel } from "@react-icons/all-files/ti/TiCancel";

import { Box, Flex, Stack } from "Atoms";
import { IconButton } from "Molecules";
import { colors } from "../../../../../../theme/colors";
import { PotentialComboDiscount } from "Types";
import { NotificationDisplay } from "./utils/notificationHelper";
import { useDiscounts } from "Providers";

type Props = {
    notification: NotificationDisplay;
    onConfirm: (e: React.MouseEvent<HTMLElement>, discount: PotentialComboDiscount, openModal: boolean) => void;
};

export const bellStyles = {
    height: "2rem",
    width: "2rem",
    borderRadius: "25px",
    paddingTop: "0.3rem",
    boxShadow: "2px -2px 7px -2px rgba(0,0,0,0.54)",
    borderColor: colors.green["500"],
    fontWeight: "bolder",
    background: "white",
    borderWidth: "1px",
    borderStyle: "solid"
};

const notificationStyle = {
    borderRadius: "2rem",
    marginBottom: "1rem",
    height: "4rem",
    cursor: "pointer",
    alignItems: "center"
};

const buttonStyles = {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "0.5rem",
    border: "0.3rem solid",
    borderColor: colors.green["500"],
    backgroundColor: colors.whiteAlpha["300"]
};

export const DiscountNotification: React.FC<Props> = ({ notification, onConfirm }) => {
    const [hideNotification, setHideNotification] = useState<Boolean>(false);
    const {
        potentialUpsell: { controlSetForcedUpsellCheck }
    } = useDiscounts();

    if (!notification || hideNotification) {
        return null;
    }

    const { discount, notificationText, openModal } = notification;

    if (discount) {
        controlSetForcedUpsellCheck(notification.discount.id!, true);
    }

    return (
        <Stack isInline stretch={4} px={4} align="center">
            <Stack
                isInline
                stretch={4}
                flex="1"
                align="center"
                style={{
                    ...buttonStyles,
                    ...notificationStyle
                }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    onConfirm(e, discount, openModal);
                }}
            >
                <Box {...bellStyles} textAlign="center" ml={3}>
                    <Box as={BsFillBellFill} size="1.3rem" color="green.500" />
                </Box>
                <Box as="b" ml={2} flex="1" color="green.500">
                    {notificationText}
                </Box>

                <Flex justifyContent="center" alignItems="center">
                    <Box as={HiCursorClick} color="green.500" mr={2} />
                    <Box color="green.500" mr={3} fontWeight="bold">
                        LÄGG TILL
                    </Box>
                </Flex>
            </Stack>

            <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setHideNotification(prev => !prev);
                    controlSetForcedUpsellCheck(discount.id!, false);
                }}
                color="red.500"
                width="3rem"
                height="3rem"
                fontSize="1.5rem"
                border="0.1rem solid"
                borderColor="red.500"
                boxShadow="md"
                backgroundColor="whiteAlpha.300"
                mb={4}
                icon={TiCancel}
            />
        </Stack>
    );
};
