import React from "react";
import { Formik } from "formik";
import { Dropdown, Radio, Form } from "semantic-ui-react";
import moment from "moment";

import { SpringButton } from "TempUtils";
import { DatePickerInput } from "../../../../../form-components/CustomInput";
import { Input, Keypad, KeypadInput, KeypadButtons, KeypadButton, DEFAULT_KEYPAD_BUTTONS } from "Atoms";
import { formatNumber } from "../utils";
import { ReceiptType } from "Constants";
import { formatDateToBackendUTCString } from "TempUtils/TextFormat";
import { Accordion, AccordionCloseIcon, AccordionContent, AccordionItem, AccordionTitle } from "Organisms";
import { removeLastChar } from "Utils";

const DateInput = props => (
    <DatePickerInput
        type="text"
        showTimeSelect
        timeFormat="HH:mm"
        locale="sv-se"
        timeCaption="Tid"
        timeIntervals={15}
        maxDate={moment()}
        dateFormat="YYYY-MM-DD HH:mm"
        customInput={<Input />}
        onChange={date => props.onChangeHandler(date)}
        {...props}
    />
);

const TextInput = ({ label, ...props }) => (
    <div>
        <label className="renderInputLabel">{label}:</label>
        <KeypadInput {...props} />
    </div>
);

const receiptTypeOptions = [
    {
        key: ReceiptType.PURCHASE,
        text: "Godkända köp",
        value: ReceiptType.PURCHASE
    },
    {
        key: ReceiptType.FAILED_BUT_PAYMENT_DONE,
        text: "Pengar har dragits men inget kvitto",
        value: ReceiptType.FAILED_BUT_PAYMENT_DONE
    },
    {
        key: ReceiptType.FAILED,
        text: "Försvunnen order",
        value: ReceiptType.FAILED
    },
    {
        key: ReceiptType.CASH_INVOICE,
        text: "Kontantfakturor",
        value: ReceiptType.CASH_INVOICE
    },
    {
        key: ReceiptType.REFUND,
        text: "Returer",
        value: ReceiptType.REFUND
    }
];

const getFormattedValues = values => {
    // if search by receiptnumber, dont use any other filter
    if (!!values.receiptNumber) {
        return {
            ...values,
            startDate: null,
            endDate: null,
            totalAmount: null,
            orderNo: null
        };
    } else {
        return {
            ...values,
            startDate: formatDateToBackendUTCString(values.startDate),
            endDate: formatDateToBackendUTCString(values.endDate),
            totalAmount: formatNumber(values.totalAmount),
            receiptNumber: formatNumber(values.receiptNumber),
            orderNo: formatNumber(values.orderNo),
            orderTypes: values.orderTypes === "POS" ? null : [values.orderTypes]
        };
    }
};

export const ReceiptForm = ({ handleSearchForOrders, CompanyRight, isExpressPOS }) => {
    const type = () => {
        if (CompanyRight) {
            if (!CompanyRight.onlineEnabled && !CompanyRight.posEnabled) {
                return null;
            } else if (CompanyRight.posEnabled) {
                return "POS";
            } else {
                return "WEB";
            }
        }
    };
    const initialValues = {
        startDate: moment().hour(0).minute(0).second(0),
        endDate: moment().hours(23).minutes(59).seconds(59),
        totalAmount: "",
        receiptNumber: "",
        orderNo: "",
        receiptType: ReceiptType.PURCHASE,
        orderTypes: type()
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const formattedValues = getFormattedValues(values);
                handleSearchForOrders(formattedValues);
                setSubmitting(false);
            }}
            render={({ handleSubmit, setFieldValue, values, isSubmitting }) => {
                let receiptTextField = "Kvittonummer";
                if (values.receiptType === ReceiptType.CASH_INVOICE) receiptTextField = "Verifikationsnummer";
                else if (values.receiptType === ReceiptType.FAILED_BUT_PAYMENT_DONE)
                    receiptTextField = "Referensnummer";

                const receiptNumberSearch = !!values.receiptNumber;
                const isBaxiFailedOrder = values.receiptType === ReceiptType.FAILED;
                return (
                    <form onSubmit={handleSubmit} className="receipt-modal-form">
                        <Keypad
                            onChange={numbers => setFieldValue("receiptNumber", numbers)}
                            value={values.receiptNumber}
                            customHandlers={{
                                DEL: value => setFieldValue("receiptNumber", removeLastChar(value))
                            }}
                        >
                            <TextInput
                                isDisabled={isBaxiFailedOrder}
                                fullWidth
                                placeholder="Valfritt"
                                label={receiptTextField}
                            />
                            <KeypadButtons buttonsClassName="receipt-modal-key-pad-buttons">
                                {DEFAULT_KEYPAD_BUTTONS.map(button => (
                                    <KeypadButton key={button.value} {...button} />
                                ))}
                            </KeypadButtons>
                        </Keypad>
                        <DateInput
                            disabled={receiptNumberSearch}
                            id="inputStartDate"
                            name="startDate"
                            placeholderText="Start datum"
                            selected={values.startDate}
                            onChangeHandler={date => setFieldValue("startDate", date)}
                        />
                        <DateInput
                            disabled={receiptNumberSearch}
                            id="inputEndDate"
                            name="endDate"
                            placeholderText="Slut datum"
                            selected={values.endDate}
                            onChangeHandler={date => setFieldValue("endDate", date)}
                        />
                        <Accordion toggle defaultIndex={[]}>
                            <AccordionItem>
                                <AccordionTitle>
                                    Avancerat
                                    <AccordionCloseIcon />
                                </AccordionTitle>
                                <AccordionContent>
                                    <Keypad
                                        onChange={numbers => setFieldValue("totalAmount", numbers)}
                                        value={values.totalAmount}
                                        customHandlers={{
                                            DEL: value => setFieldValue("totalAmount", removeLastChar(value))
                                        }}
                                    >
                                        <TextInput
                                            isDisabled={
                                                receiptNumberSearch ||
                                                values.receiptType === ReceiptType.FAILED_BUT_PAYMENT_DONE
                                            }
                                            fullWidth
                                            placeholder="Valfritt"
                                            label="Belopp"
                                        />
                                        <KeypadButtons buttonsClassName="receipt-modal-key-pad-buttons">
                                            {DEFAULT_KEYPAD_BUTTONS.map(button => (
                                                <KeypadButton key={button.value} {...button} />
                                            ))}
                                        </KeypadButtons>
                                    </Keypad>
                                    <Keypad
                                        onChange={numbers => setFieldValue("orderNo", numbers)}
                                        value={values.orderNo}
                                        customHandlers={{
                                            DEL: value => setFieldValue("orderNo", removeLastChar(value))
                                        }}
                                    >
                                        <TextInput
                                            isDisabled={
                                                receiptNumberSearch ||
                                                values.receiptType === ReceiptType.FAILED_BUT_PAYMENT_DONE
                                            }
                                            fullWidth
                                            placeholder="Valfritt"
                                            label="Ordernummer"
                                        />
                                        <KeypadButtons buttonsClassName="receipt-modal-key-pad-buttons">
                                            {DEFAULT_KEYPAD_BUTTONS.map(button => (
                                                <KeypadButton key={button.value} {...button} />
                                            ))}
                                        </KeypadButtons>
                                    </Keypad>
                                    <span style={{ marginTop: "1rem" }}>
                                        <label className="renderInputLabel">Filtrera på status:</label>
                                        <Dropdown
                                            options={receiptTypeOptions}
                                            selection
                                            fluid
                                            value={values.receiptType}
                                            onChange={(e, { value }) => setFieldValue("receiptType", value)}
                                        />
                                    </span>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                        {!isExpressPOS ? (
                            <span style={{ marginTop: "1rem" }}>
                                <Form.Field>
                                    <label className="renderInputLabel">Filtrera på:</label>
                                </Form.Field>
                                {CompanyRight && CompanyRight.posEnabled && (
                                    <Form.Field>
                                        <Radio
                                            checked={values.orderTypes === "POS"}
                                            label="Kassa"
                                            name="orderTypes"
                                            value="POS"
                                            onChange={(e, { value }) => setFieldValue("orderTypes", value)}
                                        />
                                    </Form.Field>
                                )}
                                {CompanyRight && CompanyRight.onlineEnabled && (
                                    <Form.Field>
                                        <Radio
                                            checked={values.orderTypes === "WEB"}
                                            label="Online"
                                            name="orderTypes"
                                            value="WEB"
                                            onChange={(e, { value }) => setFieldValue("orderTypes", value)}
                                        />
                                    </Form.Field>
                                )}
                            </span>
                        ) : null}
                        <SpringButton type="submit" disabled={isSubmitting} className="green-button ui big button">
                            Sök
                        </SpringButton>
                    </form>
                );
            }}
        />
    );
};
