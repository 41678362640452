import React from "react";
import Truncate from "react-truncate";

import { modConstants } from "Constants";
import { Modification, Modifications } from "Types";
import { Flex, Text } from "Atoms";

type Props = {
    modifications?: {
        flavours?: Modification[] | Modification;
        sizes?: Modification[] | Modification;
    };
    className: string;
    isOpenProduct?: boolean;
};

enum ModificationProps {
    flavours = "flavours",
    sizes = "sizes"
}

export const PosCartModifications: React.FC<Props> = ({ modifications, className, isOpenProduct = false }) => {
    if (modifications) {
        return (
            <Flex direction="column" className={className}>
                {Object.entries(modifications)
                    .filter(([key, value]) => key !== "__typename" && !!value)
                    .map(([key, value]) => {
                        const propName = key as keyof Modifications;
                        let header = key === modConstants.MOD_SIZES ? "Storlek" : "Smak";
                        if (isOpenProduct && key === ModificationProps.flavours) {
                            header = "Typ";
                        }

                        // Since our local modifications is an object and our saved ones in Mongo is an array
                        // we have to do this conditional rendering :/
                        if (Array.isArray(value)) {
                            const hasValue = !!value.length;
                            return (
                                hasValue && (
                                    <Text as="span" key={key}>
                                        <Text as="strong">{header}: </Text>
                                        {/** @ts-ignore */}
                                        <Truncate>{value.map(v => v.name)}</Truncate>
                                    </Text>
                                )
                            );
                        } else {
                            const target = modifications[propName] as Modification;
                            const hasValue = target?.name?.trim() !== "";

                            return (
                                hasValue && (
                                    <Text as="span" key={`modification-${key}`}>
                                        <Text as="strong">{header}: </Text>
                                        {/** @ts-ignore */}
                                        <Truncate>{target?.name}</Truncate>
                                    </Text>
                                )
                            );
                        }
                    })}
            </Flex>
        );
    }

    return null;
};
