import React, { useState } from "react";
import { RiArrowDownCircleLine } from "@react-icons/all-files/ri/RiArrowDownCircleLine";
import { RiArrowUpCircleLine } from "@react-icons/all-files/ri/RiArrowUpCircleLine";

import { Box } from "Atoms";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { PosComboDiscountSummary } from ".";
import { roundToTwo } from "NumberUtils";
import { ComboDiscount, Discount } from "Types";

type Props = {
    hasAppliedDiscount: boolean;
    hasComboDiscounts: boolean;
    hasAppliedDiscountTotal: boolean;
    discountNamesAndValues: any;
    comboDiscounts: ComboDiscount[] | undefined;
    discount: Discount;
    discountAmountOrRate: any;
    originalTotal: number;
    totalComboDiscountValue: number;
    vatAmount: number;
    totalAmount: number;
    discountTotal: number;
};

export const PosCartReceipt: React.FC<Props> = ({
    hasAppliedDiscount,
    hasComboDiscounts,
    hasAppliedDiscountTotal,
    originalTotal,
    totalComboDiscountValue,
    discountNamesAndValues,
    comboDiscounts,
    discount,
    discountAmountOrRate,
    discountTotal,
    vatAmount,
    totalAmount
}) => {
    const [showComboDiscount, setShowComboDiscount] = useState(false);

    return (
        <table className="receipt">
            <tbody>
                {(hasAppliedDiscount || hasComboDiscounts) && (
                    <>
                        <tr>
                            <td>
                                <span className="bold">Ord pris:</span>
                            </td>
                            <td>
                                <span>{formatSwedishFinancialNumbers(roundToTwo(originalTotal))}</span>
                            </td>
                        </tr>
                        {hasComboDiscounts && (
                            <tr>
                                <td>
                                    <span
                                        className="bold"
                                        style={{
                                            fontSize: "1.1rem",
                                            color: "#F56565",
                                            fontWeight: "bold",
                                            display: "flex"
                                        }}
                                    >
                                        {`Total rabatter (${discountNamesAndValues && discountNamesAndValues.size})`}
                                        <Box
                                            as={showComboDiscount ? RiArrowUpCircleLine : RiArrowDownCircleLine}
                                            size="18px"
                                            color="red.500"
                                            cursor="pointer"
                                            marginLeft={1}
                                            onClick={() => setShowComboDiscount(!showComboDiscount)}
                                        />
                                    </span>
                                </td>
                                <td>
                                    <span style={{ fontSize: "1.1rem", color: "#F56565", fontWeight: "bold" }}>
                                        {formatSwedishFinancialNumbers(totalComboDiscountValue)}
                                    </span>
                                </td>
                            </tr>
                        )}
                        {showComboDiscount &&
                            discountNamesAndValues &&
                            discountNamesAndValues.size > 0 &&
                            PosComboDiscountSummary(discountNamesAndValues, comboDiscounts!)}
                        {hasAppliedDiscountTotal && !hasComboDiscounts && (
                            <tr>
                                <td>
                                    <span
                                        className="bold"
                                        style={{ fontSize: "1.1rem", color: "#F56565", fontWeight: "bold" }}
                                    >
                                        Rabatt {discount.name} ({discountAmountOrRate}):
                                    </span>
                                </td>
                                <td>
                                    <span style={{ fontSize: "1.1rem", color: "#F56565", fontWeight: "bold" }}>
                                        {formatSwedishFinancialNumbers(discountTotal)}
                                    </span>
                                </td>
                            </tr>
                        )}
                    </>
                )}
                <tr>
                    <td>
                        <span className="bold">Moms:</span>
                    </td>
                    <td>
                        <span>{formatSwedishFinancialNumbers(vatAmount)}</span>
                    </td>
                </tr>
                <tr style={{ lineHeight: "30px" }}>
                    <td>
                        <span className="bold currency">Totalt:</span>
                    </td>
                    <td>
                        <span className="bold currency">{formatSwedishFinancialNumbers(totalAmount)}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
