import React from "react";
import { TiPrinter } from "@react-icons/all-files/ti/TiPrinter";

import { Flex, Button, Box } from "Atoms";
import { useTableStore, orderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import { advanceReceipt } from "../../../../../../admin/utils/AdvanceReceipt";
import { defaultValues } from "Constants";

export const PosTableSelectionButtons: React.FC<{}> = () => {
    const activeOrderWindow = orderWindowsStore(state => state.getActiveOrderWindow());
    const { selectedTable, openTable, moveTableOrder, orderWindowsToMove } = useTableStore();
    const { selectedShop } = useQoplaStore();
    const { selectedPos } = usePosStore();

    const isCartEmpty = activeOrderWindow?.cartProducts?.length === 0;
    const disableMoveBtn = !!orderWindowsToMove?.ordersToMove?.length || !selectedTable || isCartEmpty;
    const receiptPrinter = selectedPos?.receiptPrinter || defaultValues.selectedPos.receiptPrinter;
    const canPrintAdvanceReceipt = activeOrderWindow && receiptPrinter && selectedShop;

    return (
        <Flex h="auto">
            <Button
                rounded={0}
                disabled={!selectedTable}
                size="2xl"
                m={0}
                themeColor="green"
                onClick={openTable}
                isDisabled={!!selectedTable}
                minHeight={{ minHeight: "5rem" }}
                minWidth="70%"
            >
                <span>{!selectedTable ? "Välj bord" : `Öppna bord ${selectedTable.name}`}</span>
            </Button>

            <Button
                rounded={0}
                disabled={disableMoveBtn}
                size="xl"
                m={0}
                onClick={() => moveTableOrder(selectedTable?.name!, selectedTable?.id!)}
                isDisabled={!!selectedTable}
                minHeight={{ minHeight: "5rem" }}
                whiteSpace="break-spaces"
                width="30%"
            >
                <span>Flytta</span>
            </Button>
            <Button
                rounded={0}
                disabled={disableMoveBtn}
                size="lg"
                m={0}
                backgroundColor={"transparent"}
                border="1px solid #e2e2e2"
                onClick={() =>
                    canPrintAdvanceReceipt && advanceReceipt(receiptPrinter, activeOrderWindow!, selectedShop!)
                }
                isDisabled={!!selectedTable}
                minHeight={{ minHeight: "5rem" }}
                whiteSpace="break-spaces"
                width="30%"
            >
                <Box as={TiPrinter} />
            </Button>
        </Flex>
    );
};
