import { CartProduct, CombinedDiscount, ComboDiscount } from "Types";

export const getDiscountText = (discount: any) => {
    if (!discount) return null;
    if (discount.amount > 0) return `${discount.amount}kr`;
    else if (discount.rate > 0) return `${discount.rate}%`;
};

export const getDiscountTotal = (cartProducts: CartProduct[], discountId: string) => {
    return cartProducts.reduce((totalDiscount: number, cart: CartProduct) => {
        const { orderProduct } = cart;
        const discountValue =
            orderProduct?.combinedDiscounts?.reduce((value: number, discount: CombinedDiscount) => {
                return discount.discountId === discountId ? (value += discount.discountValue) : value;
            }, 0) ?? 0;
        return (totalDiscount += discountValue);
    }, 0);
};

export const getComboDiscountsIdAndValue = (
    cartProducts: CartProduct[],
    comboDiscounts: ComboDiscount[]
): Map<string, number> => {
    return comboDiscounts.reduce((discounts: Map<string, number>, comboDiscounts: ComboDiscount) => {
        const totalDiscountValue = getDiscountTotal(cartProducts, comboDiscounts.id!);
        discounts.set(comboDiscounts.id!, totalDiscountValue);
        return discounts;
    }, new Map<string, number>());
};
