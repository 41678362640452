import React, { useState, useEffect } from "react";
import { get } from "lodash";
import propTypes from "prop-types";
import { BiPurchaseTag } from "@react-icons/all-files/bi/BiPurchaseTag";
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";
import { FaArrowDown } from "@react-icons/all-files/fa/FaArrowDown";
import { FaArrowUp } from "@react-icons/all-files/fa/FaArrowUp";

import { formatSwedishFinancialNumbers, SpringButton } from "TempUtils";
import { PosCartModifications, PosCartBundleItems, CartOrderProductComment, ListItemButtons } from "./index";
import { useSplitCashRegister, useModal, usePos, useQopla, useDiscounts } from "Providers";
import { modalNames, SPLIT_TYPE } from "Constants";
import { Box, Collapsable, Flex, ListItem, Text } from "Atoms";
import { getCartProductOrgUnitPrice } from "../../../shared/utils/cartController";
import { AddonsCartItem } from "../../../shared/components";
import { IconButton } from "Molecules";
import { isVariablePriceType } from "ProductUtils";
import { getUnitStringForPriceType } from "PriceUtils";
import { roundToTwo } from "NumberUtils";
import { DISCOUNT_TYPE } from "Types";
import { DiscountNotification } from "./DiscountNotification";
import { getUpsellDiscountsToDisplay } from "./utils/notificationHelper";
import { DiscountDrawer } from "./DiscountDrawer";
import { useOrderWindowsStore, useModalStore, useTableStore } from "Stores";

export const isCartProductNotNew = (isActiveDT, activeOrderWindow, cartProductId) => {
    if (isActiveDT && !!activeOrderWindow.previousCartProducts) {
        return !!activeOrderWindow.previousCartProducts.find(prevCartProduct => prevCartProduct.id === cartProductId);
    } else {
        return false;
    }
};

export const CartContent = ({
    cartProducts,
    increment,
    decrement,
    editProduct,
    addOrderProductComment,
    addOrderProductToCart,
    handleUpdateCartProduct
}) => {
    const [activeUtilsItems, setActiveUtilsItems] = useState([]);
    const [discountForDrawer, setDiscountForDrawer] = useState([]);
    const [activeDrawerId, setActiveDrawerId] = useState(null);
    const {
        addons,
        activeOrderWindowId,
        cartUtils: { setAddonOnCartProduct },
        outOfStockIds
    } = usePos();
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const { isInTableSelectionMode } = useTableStore();
    const {
        combinedDiscount: { potentialUpsellDiscounts, numberOfUpsellsPresent },
        potentialUpsell: { getDiscountQuantitiesWithIds, setUpsellDiscount, controlSetForcedUpsellCheck }
    } = useDiscounts();
    const { selectedPos } = useQopla();
    const { isInSplitMode, splitMode, removeCartProductToPay } = useSplitCashRegister();
    const { openModal, closeModal } = useModalStore();

    const toggleActiveUtilsItems = cartProductId => {
        if (activeUtilsItems.includes(cartProductId)) {
            return setActiveUtilsItems(activeUtilsItems.filter(i => i !== cartProductId));
        }
        return setActiveUtilsItems([...activeUtilsItems, cartProductId]);
    };

    useEffect(() => {
        setActiveUtilsItems(
            activeUtilsItems.reduce((items, activeItem) => {
                if (cartProducts.some(c => activeUtilsItems.includes(c.id))) {
                    items.push(activeItem);
                }
                return items;
            }, [])
        );
    }, [cartProducts]);

    const isUnfinishedBundleProduct = cartProduct => !cartProduct.isFinished && !!cartProduct.menuBundleProduct;

    const activeOrderWindow = getActiveOrderWindow();
    const appliedDiscountsWithQuantity = getDiscountQuantitiesWithIds(activeOrderWindowId);

    let upsellToDisplay;
    if (numberOfUpsellsPresent > 0) {
        upsellToDisplay = getUpsellDiscountsToDisplay(
            cartProducts,
            potentialUpsellDiscounts,
            appliedDiscountsWithQuantity
        );
    }

    return cartProducts.map((cartProduct, index) => {
        const shouldDisableAllButtons = isCartProductNotNew(
            selectedPos.postponePaymentEnabled,
            activeOrderWindow,
            cartProduct.id
        );

        const {
            name,
            quantity,
            weight,
            priceType,
            totalPrice,
            modifications,
            selectedBundleProductItems,
            combinedDiscounts,
            discountIds,
            comment,
            addons: selectedAddons
        } = cartProduct.orderProduct;
        const { updatedUnitPrice } = cartProduct;
        const updatedUnitPriceIcon = updatedUnitPrice
            ? cartProduct.orderProduct.unitPrice > getCartProductOrgUnitPrice(cartProduct)
                ? FaArrowUp
                : FaArrowDown
            : null;
        const isEditable = !!cartProduct.orderProduct.modifications || !!cartProduct.menuBundleProduct;
        const bundleIsFinished = cartProduct.isFinished;

        let refProdId = get(cartProduct, "menuProduct.refProduct.id", null);
        if (!refProdId) refProdId = get(cartProduct, "menuBundleProduct.refBundleProduct.id", null);
        const prodAddons = addons && refProdId && addons[refProdId];

        const openAddonModal = (cartProduct, addons, preSelectedAddons, bundleItemKey = null) => {
            openModal(modalNames.POS_ADDON_MODAL, {
                posMode: true,
                addons,
                preSelectedAddons,
                outOfStockAddonNames: [...outOfStockIds],
                onSubmit: chosenAddons => {
                    setAddonOnCartProduct(cartProduct, chosenAddons, bundleItemKey);
                    closeModal(modalNames.POS_ADDON_MODAL);
                },
                onDismiss: () => {
                    closeModal(modalNames.POS_ADDON_MODAL);
                    setAddonOnCartProduct(cartProduct, [], bundleItemKey);
                },
                onClose: () => {
                    closeModal(modalNames.POS_ADDON_MODAL);
                }
            });
        };

        let itemBgColor = "";
        if (!bundleIsFinished && !!cartProduct.menuBundleProduct) {
            itemBgColor = "#ffeded";
        }

        const containsComboDiscounts = () => {
            if (!combinedDiscounts?.length) {
                return false;
            }
            return combinedDiscounts.some(discount => discount.discountType === DISCOUNT_TYPE.COMBO_DISCOUNT);
        };

        const closeDrawer = () => {
            setActiveDrawerId(null);
        };

        const activeUtilItemsOpened = activeUtilsItems.includes(cartProduct.id);
        const hasDiscount = discountIds?.length ?? false;
        const hasComboDiscounts = containsComboDiscounts();
        const openDiscountDrawer = cartProduct.id === activeDrawerId;
        const upsellOnCartProduct = upsellToDisplay?.find(value => value.cartId === cartProduct.id) ?? null;
        const shouldShowUpsellOnCartProduct = upsellOnCartProduct && !isInTableSelectionMode;
        const isOpenProduct = cartProduct.orderProduct.isOpenProduct ?? false;

        const shouldRenderUtilButton = !isInSplitMode && !isInTableSelectionMode;

        const onCartItemClick = () => {
            if (isInTableSelectionMode) {
                return;
            }

            if (isInSplitMode) {
                removeCartProductToPay(cartProduct);
            } else {
                if (isUnfinishedBundleProduct(cartProduct)) {
                    editProduct(cartProduct);
                } else {
                    toggleActiveUtilsItems(cartProduct.id);
                }
            }
        };

        return (
            <ListItem key={index} onClick={onCartItemClick} backgroundColor={itemBgColor}>
                <Box>
                    <Flex align="center" mb={2} px={4}>
                        {isInSplitMode && splitMode === SPLIT_TYPE.DISHES && (
                            <IconButton
                                icon={FiMinus}
                                size="lg"
                                mr={4}
                                onClick={e => {
                                    e.stopPropagation();
                                    removeCartProductToPay(cartProduct);
                                }}
                            />
                        )}
                        {(hasDiscount || hasComboDiscounts) && (
                            <Box as={BiPurchaseTag} color="red.500" size="18px" mr={1} />
                        )}
                        {updatedUnitPrice && <Box size="18px" mr="6px" as={updatedUnitPriceIcon} color="red.600" />}
                        <Text fontSize="2xl" fontWeight="bold" color="gray.900" mb={0} flex="1">
                            {name}
                        </Text>
                        <Flex justify="space-between" align="center">
                            <Text mb={0} mr={4} fontSize="xl" color="gray.900">
                                {isVariablePriceType(priceType)
                                    ? `${roundToTwo(weight)} ${getUnitStringForPriceType(
                                          priceType
                                      )} | ${formatSwedishFinancialNumbers(totalPrice)}`
                                    : `${quantity} st | ${formatSwedishFinancialNumbers(totalPrice)}`}
                            </Text>
                            {shouldRenderUtilButton && (
                                <SpringButton
                                    className="ui icon button basic"
                                    style={{
                                        fontSize: "1em"
                                    }}
                                    customOnClick={e => {
                                        e.stopPropagation();
                                        toggleActiveUtilsItems(cartProduct.id);
                                    }}
                                >
                                    <Box as={FaEllipsisH} size="22px" />
                                </SpringButton>
                            )}
                        </Flex>
                    </Flex>

                    <Box mr={4} ml={8}>
                        {modifications && (
                            <PosCartModifications
                                modifications={modifications}
                                className="pos-modification"
                                isOpenProduct={isOpenProduct}
                            />
                        )}
                        {selectedBundleProductItems && (
                            <PosCartBundleItems
                                cartProduct={cartProduct}
                                selectedBundleItems={selectedBundleProductItems}
                                activeUtilItemsOpened={activeUtilItemsOpened}
                                shouldDisableAllButtons={shouldDisableAllButtons}
                                addons={addons}
                                openAddonModal={openAddonModal}
                                className="pos-modification"
                            />
                        )}
                        {comment && <CartOrderProductComment comment={comment} />}
                        {selectedAddons && <AddonsCartItem addons={selectedAddons} ml={4} />}
                    </Box>

                    {shouldShowUpsellOnCartProduct && (
                        <DiscountNotification
                            notification={upsellOnCartProduct}
                            onConfirm={(e, discount, openModal) => {
                                e.stopPropagation();
                                if (!openModal) {
                                    increment(cartProduct);
                                    setUpsellDiscount(discount.id, refProdId);
                                    controlSetForcedUpsellCheck(discount.id, false);
                                } else {
                                    setDiscountForDrawer(discount);
                                    setActiveDrawerId(cartProduct.id);
                                }
                            }}
                        />
                    )}
                    {openDiscountDrawer && (
                        <DiscountDrawer
                            openDrawer={openDiscountDrawer}
                            onCloseDrawer={closeDrawer}
                            discount={discountForDrawer}
                            cartProducts={cartProducts}
                            setUpsellDiscountId={setUpsellDiscount}
                            increment={cartProduct => increment(cartProduct)}
                        />
                    )}

                    <Collapsable open={activeUtilItemsOpened}>
                        <Box backgroundColor="gray.300">
                            <Flex mx={4} justify="flex-end" py={4}>
                                <ListItemButtons
                                    prodAddons={prodAddons}
                                    openAddonModal={openAddonModal}
                                    cartProduct={cartProduct}
                                    index={index}
                                    shouldDisableAllButtons={shouldDisableAllButtons}
                                    utilFuncs={{
                                        editProduct,
                                        addOrderProductComment,
                                        addOrderProductToCart,
                                        handleUpdateCartProduct
                                    }}
                                    isEditable={!isOpenProduct ? isEditable : false}
                                    quantity={quantity}
                                />
                            </Flex>
                        </Box>
                    </Collapsable>
                </Box>
            </ListItem>
        );
    });
};

CartContent.propTypes = {
    cartProducts: propTypes.arrayOf(propTypes.object).isRequired,
    increment: propTypes.func.isRequired,
    decrement: propTypes.func.isRequired,
    removeCartProduct: propTypes.func.isRequired,
    editProduct: propTypes.func.isRequired
};
