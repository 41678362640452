import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import { useEventCallback } from "Hooks";
import { Flex, Input, Keypad, KeypadButtons, KeypadButton, DEFAULT_KEYPAD_BUTTONS } from "Atoms";

/**Instead of having password type on the input as that could bring up the prompt
 * also removed the up and down arrows for input type = number
 */
const FlexExtension = styled(Flex)`
    input {
        -webkit-text-security: disc;
        -moz-text-security: disc;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        -moz-appearance: textfield;
    }
`;

type Props = {
    pinLength: number;
    onPassBackCode: (pin: string) => void;
    shouldShowKeyPad?: boolean;
};

export const PinCode: React.FC<Props> = ({ pinLength, onPassBackCode, shouldShowKeyPad = false }) => {
    /**Using mutltiple refs */
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const pinMapArray = [...Array(pinLength)].map((_, i) => i + 1);
    /**Initial set an array to the size of the pin length requested */
    const [pinValues, setPinValues] = useState<string[]>([...Array(pinLength)].map((_, i) => ""));

    /** set input focus based on the index of the input */
    const setInputFocus = (index: number) => {
        inputRefs.current[index].focus();
    };

    /** set the input value in the array based on the index will remove or add
     * then if the value is added it moved the focus onto the next input
     */
    const onInputChange = (inputValue: string, inputIndex: number) => {
        
        let updatePinValues = [...pinValues];

        /** Preventing multi value in input box & last value added */
        const lastInputValue = inputValue.length > 1 ? inputValue.charAt(inputValue.length - 1) : inputValue;
        if (!lastInputValue) {
            updatePinValues[inputIndex] = lastInputValue;
            if (inputIndex !== 0) {
                setInputFocus(inputIndex - 1);
            }
        } else {
            updatePinValues[inputIndex] = lastInputValue;
            if (pinMapArray.length - 1 !== inputIndex) {
                setInputFocus(inputIndex + 1);
            } else {
                setInputFocus(inputIndex);
            }
        }

        setPinValues(updatePinValues);
    };

    useEffect(() => {
        if (inputRefs.current) {
            inputRefs.current[0].focus();
        }
    }, []);

    /** checks the pin code
     * e.g if pin code is 4 digits then it makes sure that there are 4 numbers in the state
     * and then passes back
     */
    useEffect(() => {
        if (pinValues) {
            const pinHasBeenEntered = pinValues.every((value: string) => {
                return value !== "";
            });
            if (pinHasBeenEntered) {
                onPassBackCode(pinValues.join(""));
            }
        }
    }, [pinValues]);

    useEventCallback({
        eventName: "keydown",
        callback: (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                e.preventDefault();
                onPassBackCode(pinValues.join(""));
            }
        }
    });

    const standardColours = {
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "gray.600"
    };
    const numberOfemptyElements = pinValues.filter(pin => pin == "").length;
    const currentIdx = pinLength - numberOfemptyElements;

    const handleKeyPadClick = (buttonValue: string) => {
        if (buttonValue.includes("DEL")) {
            const idxToDelete = currentIdx - 1 >= 0 ? currentIdx - 1 : currentIdx;
            onInputChange("", idxToDelete);
        } else {
            if (currentIdx <= pinLength - 1) {
                onInputChange(buttonValue, currentIdx);
            }
        }
    };

    return (
        <>
            <FlexExtension justify="center" width="100%">
                {pinMapArray.map((key: number, index: number) => {
                    return (
                        <Input
                            {...standardColours}
                            _hover={{
                                ...standardColours
                            }}
                            isDisabled={shouldShowKeyPad}
                            maxLength={1}
                            key={key}
                            width="5rem"
                            height="5rem"
                            fontSize="3rem"
                            margin="1rem"
                            textAlign="center"
                            data-index={index}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onInputChange(event.currentTarget.value, index);
                            }}
                            ref={(element: HTMLInputElement) => {
                                inputRefs.current[index] = element;
                            }}
                            value={pinValues[index]}
                            type="number"
                        />
                    );
                })}
            </FlexExtension>
            {shouldShowKeyPad && (
                <Keypad
                    isDefaultOpen
                    closeOnOutsideClick={false}
                    onChange={(buttonValue: any) => {
                        handleKeyPadClick(buttonValue);
                    }}
                    value={pinValues[currentIdx]}
                    w="100%"
                >
                    <KeypadButtons borderTop="1px solid #F7FAFC">
                        {DEFAULT_KEYPAD_BUTTONS.map(button => (
                            <KeypadButton key={button.value} {...button} />
                        ))}
                    </KeypadButtons>
                </Keypad>
            )}
        </>
    );
};
