import React from "react";

import { SpringButton } from "TempUtils";
import { PaymentMethod } from "Constants";
import { Modal, ModalHeader, ModalBody } from "Molecules";
import { Header, Button, NewDivider as Divider, NewGrid as Grid, Text, Box } from "Atoms";
import { usePosStore } from "Stores";
import { getCartTotalPrice } from "../utils";

// TODO fix terminalid to terminal object
export const PosTerminalsModal = ({ posTerminals, setOpenPosTerminalsModal, makePayment, cartProducts }) => {
    const { setActiveTerminalIds, activeTerminalIds } = usePosStore();

    const handleMakePayment = terminalId => {
        const amount = getCartTotalPrice(cartProducts);
        makePayment({ paymentMethod: PaymentMethod.CARD, amount }, null, terminalId);
        setActiveTerminalIds([...activeTerminalIds, terminalId]);
        setOpenPosTerminalsModal(false);
    };

    return (
        <Modal open isScrolling size="lg">
            <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
                <Header as="h3" size="lg" m="0">
                    Välj en terminal
                </Header>
                <Button themeColor="red" size="lg" onClick={() => setOpenPosTerminalsModal(false)}>
                    Stäng
                </Button>
            </ModalHeader>
            <ModalBody>
                <Grid templateColumns="1fr 1fr 1fr" gridColumnGap={8}>
                    {posTerminals.map(({ terminalId, name, description }) => {
                        const terminalIsActive = activeTerminalIds.includes(terminalId);
                        return (
                            <SpringButton
                                key={terminalId}
                                disabled={terminalIsActive}
                                customOnClick={() => handleMakePayment(terminalId)}
                            >
                                <Box p={6} w="full" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                    <Text fontSize="xl" m="0">
                                        {name}
                                    </Text>
                                    {terminalIsActive && <Text color="red.500">(Upptagen)</Text>}
                                    <Divider color="gray.500" my={4} />
                                    <Text as="span">{description}</Text>
                                </Box>
                            </SpringButton>
                        );
                    })}
                </Grid>
            </ModalBody>
        </Modal>
    );
};
