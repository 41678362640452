import { CartProduct, PotentialComboDiscount, SelectedCartProduct, UpsellDiscount } from "Types";

export type NotificationDisplay = {
    cartId: string;
    discountId: string;
    discount: PotentialComboDiscount;
    notificationText: string;
    productText: string | null;
    openModal: boolean;
};

export const getUpsellDiscountsToDisplay = (
    cartProducts: CartProduct[],
    upsellDiscounts: UpsellDiscount[],
    appliedDiscounts: Map<string, number>
) => {
    return cartProducts.reduce((notifications: NotificationDisplay[], cartProduct: CartProduct) => {
        const cartUpsell = upsellDiscounts?.find(upsell => upsell.cartId === cartProduct.id);
        if (!cartUpsell) {
            return notifications;
        }
        const discountsAvailable = cartUpsell?.discounts && cartUpsell.discounts.length;

        if (!discountsAvailable) {
            return notifications;
        }

        const cartIdInDiscounts: PotentialComboDiscount[] =
            cartUpsell?.discounts?.filter((discount: PotentialComboDiscount) => {
                return discount.selectedCartProducts?.some(
                    (select: SelectedCartProduct) => select.cartId === cartUpsell.cartId
                );
            }) ?? [];

        if (!cartIdInDiscounts.length) {
            return notifications;
        }

        let discount = {} as PotentialComboDiscount;

        const cartDiscounts = cartIdInDiscounts.reduce(
            (upsell: Map<string, boolean>, potentialDiscount: PotentialComboDiscount) => {
                const { limit } = potentialDiscount.comboCategories[0] ?? 0;
                const currentDiscountQuantity = appliedDiscounts?.get(potentialDiscount.id!) ?? 0;
                if (currentDiscountQuantity === 0) {
                    discount = potentialDiscount;
                    upsell.set(potentialDiscount.id!, true);
                    return upsell;
                }
                const { potentialApplied } = cartUpsell;
                const timesApplied = currentDiscountQuantity / limit;
                const potentialApplication = potentialApplied?.get(potentialDiscount.id!) ?? 0;
                if (potentialApplication > timesApplied) {
                    discount = potentialDiscount;
                    upsell.set(potentialDiscount.id!, true);
                    return upsell;
                }
                upsell.set(potentialDiscount.id!, false);
                return upsell;
            },
            new Map()
        );
        
        const displayNotification = Array.from(cartDiscounts).some((value: [string, boolean]) => value[1] === true);

        if (!displayNotification) {
            return notifications;
        }

        const alreadyHasDiscount = notifications.some(
            (value: NotificationDisplay) => value.discountId === discount.id!
        );

        if (alreadyHasDiscount) {
            return notifications;
        }

        const discountCanBeApplied = cartUpsell.potentialApplied?.get(discount.id!) ?? 0;

        let notificationText = `${discount.name}`;

        if (discountCanBeApplied > 1) {
            notificationText = `${discountCanBeApplied} x ( ${discount.name} )`;
        }

        const numberOfProducts = discount.comboCategories[0].menuProductIds.length;
        const productText = numberOfProducts > 1 ? `( Välj bland ${numberOfProducts} produkter )` : null;
        const openModal = (!!productText && numberOfProducts > 1) ?? false;

        const displayedNotification: NotificationDisplay = {
            cartId: cartProduct.id,
            discountId: discount.id!,
            discount: discount,
            notificationText: notificationText,
            productText: productText,
            openModal: openModal
        };

        notifications = [...notifications, displayedNotification];

        return notifications;
    }, []);
};
