import React from "react";

import { Box } from "Atoms";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { ComboDiscount } from "Types";

export const PosComboDiscountSummary = (discounts: Map<string, number>, comboDiscounts: ComboDiscount[]) => {
    if (discounts.size === 0) {
        return null;
    }
    const discountArray = [...Array.from(discounts!)];
    const styles = {
        fontSize: "0.9rem",
        fontWeight: "bold",
        color: "red.500"
    };
    return discountArray.map((valueName: [string, number]) => {
        const discountName =
            comboDiscounts?.find((discount: ComboDiscount) => discount.id === valueName[0])?.name ?? valueName[0];

        return (
            <tr key={valueName[0]}>
                <td>
                    <Box as="span" {...styles}>
                        {discountName}
                    </Box>
                </td>
                <td>
                    <Box as="span" {...styles}>
                        {formatSwedishFinancialNumbers(valueName[1])}
                    </Box>
                </td>
            </tr>
        );
    });
};
