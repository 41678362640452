import React, { useEffect, useState } from "react";
import { BiCalculator } from "@react-icons/all-files/bi/BiCalculator";
import { RiScales2Fill } from "@react-icons/all-files/ri/RiScales2Fill";

import { Modal, ModalBody, ModalCloseBtn, RadioButtonGroup } from "Molecules";
import { Header, Flex, Button, Box, CustomRadioButton, Keypad, KeypadButtons, KeypadButton } from "Atoms";
import { MenuProduct, PriceType } from "Types";
import { useLocalStorage, useWeightScale } from "Hooks";
import { LOCAL_STORAGE_CONSTANTS } from "Constants";
import { getNumDecimalPlaces, roundTo } from "NumberUtils";
// Probably make a "Devices" or "Scales" import for this
import { ScaleWeightResponse, ScaleUnit } from "../../../../../../devices/scales/ScaleInterfaceAPI";
import { Stathmos3070 } from "../../../../../../devices/scales/models/Stathmos3070";
import { removeLastChar } from "Utils";

type Props = {
    modalContent: {
        onClose: () => void;
        menuProduct: MenuProduct;
        modalCallback: (weight: number, scaleType: ScaleInputMode, menuProduct: MenuProduct) => void;
        disableManualOnLock?: boolean;
    };
};

const manualWeightInputButtons = [
    { value: 1, children: 1 },
    { value: 2, children: 2 },
    { value: 3, children: 3 },
    { value: 4, children: 4 },
    { value: 5, children: 5 },
    { value: 6, children: 6 },
    { value: 7, children: 7 },
    { value: 8, children: 8 },
    { value: 9, children: 9 },
    { value: ",", children: "," },
    { value: 0, children: 0 },
    { value: "DEL", children: "DEL", themeColor: "red" }
];

/**
 * Returns an array of disabled keypad values based.
 * Targeted to determine whether the decimal/comma delimiter buttons should be disabled.
 * @param keypadValue string representation of keypad value with decimal delimiters
 * @param priceType
 */
const getListOfDisabledKeypadButtons = (keypadValue: string, priceType: PriceType) => {
    const disabledKeypadButtonValues = new Set();

    // Check if maximum precision has been reached
    const numAllowedDecimalPlaces = getNumWeightDecimalPlacesForPriceType(priceType);
    const numDecimalPlaces = getNumDecimalPlaces(keypadValue);

    if (numAllowedDecimalPlaces === 0) {
        disabledKeypadButtonValues.add(",");
    } else if (numDecimalPlaces >= numAllowedDecimalPlaces) {
        manualWeightInputButtons.forEach(keypadButton => {
            if (keypadButton.value !== "DEL") {
                disabledKeypadButtonValues.add(keypadButton.value);
            }
        });
    }

    // Check if delimiter is already present
    const keypadValueHasDelimiter = keypadValue.indexOf(".") !== -1;
    if (keypadValueHasDelimiter) {
        disabledKeypadButtonValues.add(",");
    }

    return Array.from(disabledKeypadButtonValues.values());
};

const lerp = (timeDelta: number, startValue: number, targetValue: number) => {
    const TIME_TO_LERP = 750;
    const completionFactor = Math.min(TIME_TO_LERP, timeDelta) / TIME_TO_LERP;
    return startValue + (targetValue - startValue) * completionFactor;
};

const getCurrentTime = () => new Date().getTime();

const getUnitLabelForPriceType = (priceType: PriceType): string => {
    switch (priceType) {
        case PriceType.PRICE_PER_GRAM:
            return "g";
        case PriceType.PRICE_PER_HECTOGRAM:
            return "hg";
        case PriceType.PRICE_PER_KILOGRAM:
            return "kg";
        case PriceType.PRICE_PER_UNIT:
            return "styck";
        default:
            return "";
    }
};

const getNumWeightDecimalPlacesForPriceType = (priceType: PriceType): number => {
    switch (priceType) {
        case PriceType.PRICE_PER_GRAM:
            return 0;
        case PriceType.PRICE_PER_HECTOGRAM:
            return 1;
        case PriceType.PRICE_PER_KILOGRAM:
            return 2;
        case PriceType.PRICE_PER_UNIT:
            return 0;
        default:
            return 0;
    }
};

const convertPriceTypeUnitToScaleUnit = (priceType: PriceType): ScaleUnit => {
    switch (priceType) {
        case PriceType.PRICE_PER_GRAM:
            return "g";
        case PriceType.PRICE_PER_HECTOGRAM:
            return "hg";
        case PriceType.PRICE_PER_KILOGRAM:
            return "kg";
        default:
            console.warn(`Could not find matching priceType: ${priceType}`);
            return "kg";
    }
};

const convertScaleWeightToPriceTypeWeight = (weight: number, scaleUnit: ScaleUnit, priceType: PriceType): number => {
    let grams = 0;

    // Convert anything to grams first
    switch (scaleUnit) {
        case "g":
            grams = weight * 1.0;
            break;
        case "kg":
            grams = weight * 1000.0;
            break;
        case "t":
            grams = weight * 1000000.0;
            break;
        default:
            console.warn(`Could not find matching scaleUnit: ${scaleUnit}`);
            break;
    }

    // Now convert into the appropriate unit given the PriceType
    switch (priceType) {
        case PriceType.PRICE_PER_GRAM:
            return grams;
        case PriceType.PRICE_PER_HECTOGRAM:
            return grams / 100;
        case PriceType.PRICE_PER_KILOGRAM:
            return grams / 1000;
        default:
            console.warn(`Could not find matching priceType: ${priceType}`);
    }

    return weight;
};

export enum ScaleInputMode {
    Manual = "MANUAL",
    Auto = "AUTO"
}

export const ScaleModal: React.FC<Props> = ({
    modalContent: { onClose, menuProduct, modalCallback, disableManualOnLock = false }
}) => {
    const scale = useWeightScale(Stathmos3070);
    const [hasConnectedToScaleSuccessfully, setHasConnectedToScaleSuccesfully] = useLocalStorage(
        LOCAL_STORAGE_CONSTANTS.HAS_CONNECTED_TO_SCALE
    );

    const priceType = menuProduct.refProduct.priceType;
    const unitLabel = getUnitLabelForPriceType(priceType);
    const numWeightDecimalPlaces = getNumWeightDecimalPlacesForPriceType(priceType);

    // Manual input  state
    const initialScaleInputMode = hasConnectedToScaleSuccessfully ? ScaleInputMode.Auto : ScaleInputMode.Manual;
    const [scaleInputMode, setScaleInputMode] = useState<ScaleInputMode>(initialScaleInputMode);
    const [manualInputWeight, setManualInputWeight] = useState("");
    const isScaleInManualMode = scaleInputMode === ScaleInputMode.Manual;

    // Scale specific state
    const [startWeight, setStartWeight] = useState(0);
    const [targetWeightInfo, setTargetWeightInfo] = useState<ScaleWeightResponse>({
        isChanging: false,
        weight: 0,
        unit: "kg"
    });
    const [startTime, setStartTime] = useState(getCurrentTime());
    const [viewedWeight, setViewedWeight] = useState(0);

    const WEIGHT_UI_UPDATE_FREQUENCY_MS = 750;
    if (getCurrentTime() - startTime < WEIGHT_UI_UPDATE_FREQUENCY_MS) {
        requestAnimationFrame(() => {
            setViewedWeight(lerp(getCurrentTime() - startTime, startWeight, targetWeightInfo.weight));
        });
    }

    useEffect(() => {
        if (isScaleInManualMode) {
            return;
        }

        const WEIGHT_POLL_TIMER_MS = 1000;
        const weightCheckInterval = setInterval(async () => {
            try {
                // Don't read from the scale during manual input
                if (isScaleInManualMode) {
                    return;
                }

                if (!scale.isConnected()) {
                    scale.connect();
                }

                if (!scale.isReady()) {
                    return;
                }

                if (!hasConnectedToScaleSuccessfully) {
                    setHasConnectedToScaleSuccesfully(true);
                }

                // Read the weight info
                const weightInfo = await scale.readWeight();

                if (!!weightInfo.error) {
                    throw weightInfo.error;
                }

                const convertedWeightInfo = { ...weightInfo };
                convertedWeightInfo.weight = convertScaleWeightToPriceTypeWeight(
                    weightInfo.weight,
                    weightInfo.unit,
                    priceType
                );
                convertedWeightInfo.unit = convertPriceTypeUnitToScaleUnit(priceType);

                // Convert the weight to the correct unit
                setTargetWeightInfo(convertedWeightInfo);
            } catch (error) {
                console.warn(`Error reading from scale: ${JSON.stringify(error)}`);
            }
        }, WEIGHT_POLL_TIMER_MS);

        return () => {
            clearInterval(weightCheckInterval);
        };
    }, [scaleInputMode]);

    useEffect(() => {
        setStartTime(getCurrentTime());
        setStartWeight(viewedWeight);
    }, [targetWeightInfo]);

    useEffect(() => {
        if (isScaleInManualMode) {
            verifyAndSaveManualInputWeight(manualInputWeight);
        }
    }, [manualInputWeight]);

    useEffect(() => {
        if (disableManualOnLock) {
            setScaleInputMode(ScaleInputMode.Auto);
        }
    }, [disableManualOnLock]);

    const submitWeight = (registeredWeight: number) => {
        const roundedWeight = roundTo(registeredWeight, numWeightDecimalPlaces);
        modalCallback && modalCallback(roundedWeight, scaleInputMode, menuProduct);
        onClose();
    };

    const verifyAndSaveManualInputWeight = (weight: string) => {
        let newWeight = 0;
        try {
            const parsedWeight = parseFloat(weight);
            if (isNaN(parsedWeight)) {
                throw "Invalid weight value";
            }
            newWeight = parsedWeight;
        } catch (e) {
            // Maybe try to cleanse the input, but for now, set to 0
        }
        setViewedWeight(newWeight);
        setTargetWeightInfo({ weight: newWeight, unit: convertPriceTypeUnitToScaleUnit(priceType), isChanging: false });
        setStartWeight(newWeight);
    };

    const onKeyPadValueChanged = (keypadValue: string) => {
        /**
         * This is here because we want a ',' on the actual KeyPad
         * But, javascript doesn't parse '3,14'  we need to provide '3.14' for it to be a number
         */
        const keypadValueWithNoCommas = keypadValue.replace(/\,/g, ".");

        /**
         * This will probably be used wherever we want to convert a string
         *  to a currency number in the future.
         *
         * This, together with the comma replacement should should be put in "NumberUtils"
         * later
         */
        const isValidNumberString = (numberString: string): boolean => {
            const numDecimalsInString = (numberString.match(/\./g) || []).length;
            const hasTooManyDecimals = numDecimalsInString > 1;
            return !hasTooManyDecimals;
        };

        if (isValidNumberString(keypadValueWithNoCommas)) {
            setManualInputWeight(keypadValueWithNoCommas);
        }
    };

    const isSubmitWeightDisabled = (): boolean => {
        const isScaleInAutoMode = !isScaleInManualMode;
        return viewedWeight <= 0 || (isScaleInAutoMode && targetWeightInfo.isChanging);
    };

    return (
        <Modal open={true} onClose={onClose} closeOnEscape={true}>
            <ModalBody padding="2rem" textAlign="center">
                <RadioButtonGroup
                    name="scaleMode"
                    marginTop={2}
                    marginBottom={2}
                    activeThemeColor="green"
                    value={scaleInputMode}
                    onValueChanged={(selectedValue: string) => {
                        setScaleInputMode(selectedValue as ScaleInputMode);
                    }}
                    rounded="md"
                >
                    <CustomRadioButton rightIcon={RiScales2Fill} value={ScaleInputMode.Auto}>
                        Auto
                    </CustomRadioButton>
                    <CustomRadioButton
                        rightIcon={BiCalculator}
                        value={ScaleInputMode.Manual}
                        display={disableManualOnLock ? "none" : "visible"}
                    >
                        Manual
                    </CustomRadioButton>
                </RadioButtonGroup>
                <Box as={RiScales2Fill} size="5rem" />

                <Flex justify="space-between" width="100%" flexDirection="column">
                    <Header as="h1" size="xl" margin="0">
                        {`${viewedWeight.toFixed(numWeightDecimalPlaces)} ${unitLabel}`}
                    </Header>
                    <ModalCloseBtn />

                    <Keypad
                        isOpen={isScaleInManualMode}
                        onChange={(num: any) => onKeyPadValueChanged(String(num))}
                        value={manualInputWeight}
                        disabledButtons={getListOfDisabledKeypadButtons(manualInputWeight, priceType)}
                        customHandlers={{
                            DEL: (value: string) => {
                                onKeyPadValueChanged(removeLastChar(value));
                            }
                        }}
                    >
                        <KeypadButtons>
                            {manualWeightInputButtons.map(button => (
                                <KeypadButton key={button.value} {...button} />
                            ))}
                        </KeypadButtons>
                    </Keypad>

                    <Button
                        size="2xl"
                        themeColor="green"
                        disabled={isSubmitWeightDisabled()}
                        width="100%"
                        onClick={() => {
                            submitWeight(targetWeightInfo.weight);
                        }}
                    >
                        Spara
                    </Button>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
