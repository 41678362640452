import React from "react";
import { FcLock } from "@react-icons/all-files/fc/FcLock";

import { SpringButton } from "TempUtils";
import { isPosRefundButtonLocked, swedishPaymentMethod } from "Utils";
import { useLocalPosSettings } from "Providers";
import { disableButtonsMap, PaymentMethod } from "Constants";
import { useAwaitConfirmAccessCodeModal } from "../../PosUnlockLockModal";

export const RefundButton = ({
    orderPaymentMethod,
    refund,
    disableMap,
    addDisabled,
    handleRefundOrder,
    isManualCardPayment,
    isRefundForExpressPOS
}) => {
    const {
        posSettings: { lockableParts }
    } = useLocalPosSettings();

    const verifyAccessCode = useAwaitConfirmAccessCodeModal();
    const isRefundLocked = !isRefundForExpressPOS ? isPosRefundButtonLocked(lockableParts) : false;
    const hasLockedIcon = {
        leftIcon: isRefundLocked ? FcLock : undefined
    };

    let paymentMethodTitle = swedishPaymentMethod[orderPaymentMethod];
    const paymentMethodText =
        isManualCardPayment && paymentMethodTitle.engConstant == PaymentMethod.CARD
            ? swedishPaymentMethod["MANUAL_CARD"].swe
            : paymentMethodTitle.swe;

    const onHandleRefund = () => {
        addDisabled(`${disableButtonsMap.REFUND_BUTTON}${refund.referenceOrderId}`);
        //handleRefundOrder as a first argument will expect payment method
        //basically engConstant is one of the payment methods in enum PaymentMethod(from Constants)
        handleRefundOrder(paymentMethodTitle.engConstant, isManualCardPayment);
    };

    const onRefundClick = async () => {
        if (isRefundLocked) {
            const accessGranted = await verifyAccessCode();
            if (accessGranted) {
                onHandleRefund();
            }
        } else {
            onHandleRefund();
        }
    };

    return (
        <SpringButton
            className="ui big button return"
            disabled={
                disableMap[`${disableButtonsMap.REFUND_BUTTON}${refund.referenceOrderId}`] ||
                refund.sum === 0 ||
                !refund.productsToRefund.length
            }
            customOnClick={onRefundClick}
            {...hasLockedIcon}
        >
            <span>Retur {paymentMethodText}</span>
        </SpringButton>
    );
};
