import React from "react";
import { Accordion, Table, Icon, Header } from "semantic-ui-react";
import { formatDateToLocal } from "TempUtils";
import "../index.scss";
import { isVariablePriceType } from "ProductUtils";
import { getUnitStringForPriceType, formatFinancialNumbers } from "PriceUtils";
import { useLanguage } from "Providers";
import { useQoplaStore } from "Stores";

// TODO: Please note, refundedProducts is actually an array of Orders !
export const PastReturns = ({ openAccordion, setOpenAccordion, refundedProducts }) => {
    const { translate, translateWithArgument } = useLanguage();
    const { companyLocale } = useQoplaStore();

    const isActive = openAccordion === 0;
    return (
        <Accordion fluid styled className="past-returns-accordion">
            <Accordion.Title
                className="title"
                index={0}
                active={isActive}
                onClick={() => refundedProducts.length && setOpenAccordion(openAccordion === 0 ? -1 : 0)}
            >
                {translateWithArgument("earlierRefunds", `${refundedProducts.length}${translate("quantityAbrev")}`)}
                <Icon name="dropdown" size="large" flipped={isActive ? "vertically" : "horizontally"} />
            </Accordion.Title>
            <Accordion.Content active={isActive}>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{translate("receiptNo")}.</Table.HeaderCell>
                            <Table.HeaderCell>{translate("refundDate")}</Table.HeaderCell>
                            <Table.HeaderCell>{translate("products")}</Table.HeaderCell>
                            <Table.HeaderCell>{translate("comment")}</Table.HeaderCell>
                            <Table.HeaderCell>{translate("sum")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {refundedProducts.map((rp, i) => (
                            <Table.Row key={rp.orderNo + i} verticalAlign="top">
                                <Table.Cell>
                                    <Header as="h3">{rp.receiptNumber}</Header>
                                </Table.Cell>
                                <Table.Cell>{formatDateToLocal(rp.purchaseDate)}</Table.Cell>
                                <Table.Cell>
                                    <ul>
                                        {rp.orderProducts.map((op, idx) => {
                                            const hasVariablePriceType = isVariablePriceType(op.priceType);
                                            const unitString = getUnitStringForPriceType(op.priceType);
                                            return (
                                                <li key={op.name + idx}>
                                                    {hasVariablePriceType
                                                        ? `${op.weight} ${unitString} x ${op.name}`
                                                        : `${op.quantity} st ${op.name}`}

                                                    {op.selectedBundleProductItems && (
                                                        <ul style={{ margin: 0, padding: 0 }}>
                                                            {op.selectedBundleProductItems.map((selectedItem, k) => (
                                                                <li
                                                                    key={i + op.refBundleProductId + k}
                                                                    style={{
                                                                        paddingLeft: "0.7em",
                                                                        color: "grey",
                                                                        fontSize: "0.7em",
                                                                        listStyleType: "none"
                                                                    }}
                                                                >
                                                                    {selectedItem.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    {op.addons && (
                                                        <ul style={{ margin: 0, padding: 0 }}>
                                                            {op.addons.map((addon, k) => (
                                                                <li
                                                                    key={i + op.refBundleProductId + k}
                                                                    style={{
                                                                        paddingLeft: "0.7em",
                                                                        color: "grey",
                                                                        fontSize: "0.7em",
                                                                        listStyleType: "none"
                                                                    }}
                                                                >
                                                                    {addon.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Table.Cell>
                                <Table.Cell>{rp.comment ? rp.comment : "Ingen kommentar"}</Table.Cell>
                                <Table.Cell>{formatFinancialNumbers(rp.totalAmount, companyLocale)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Accordion.Content>
        </Accordion>
    );
};
