import React, { useState } from "react";
import { BiHappy } from "@react-icons/all-files/bi/BiHappy";
import { FiDivideCircle } from "@react-icons/all-files/fi/FiDivideCircle";

import { Box, Button, Flex, Header, Keypad, KeypadInput, KeypadButtons, KeypadButton, Text } from "Atoms";
import { InputGroup, InputRightElement } from "Molecules";

const amountButtons = [
    { value: "1", children: 1 },
    { value: "2", children: 2 },
    { value: "3", children: 3 },
    { value: "4", children: 4 },
    { value: "5", children: 5 },
    { value: "6", children: 6 },
    { value: "7", children: 7 },
    { value: "8", children: 8 },
    { value: "9", children: 9 },
    { value: "", children: "" },
    { value: 0, children: 0 },
    { value: "Dela", children: "Dela", themeColor: "green" }
];
type Props = {
    everythingHasBeenPayed: boolean;
    hasNoAvailableLeft: boolean;
    splitByNumber: (numberOfReceipts: number) => void;
    zoomFactor: number;
};

export const SplitByNumber: React.FC<Props> = ({
    everythingHasBeenPayed,
    hasNoAvailableLeft,
    splitByNumber,
    zoomFactor
}) => {
    const [numberOfReceipts, setNumberOfReceipts] = useState("");

    return (
        <Flex
            p={8}
            bg="white"
            direction="column"
            rounded="md"
            height={`${53 * zoomFactor}vh`}
            minHeight={`${30 * zoomFactor}vh`}
            position="relative"
        >
            {(everythingHasBeenPayed || hasNoAvailableLeft) && (
                <Flex
                    bg="rgb(255 255 255 / 90%)"
                    height="100%"
                    width="100%"
                    zIndex={10}
                    position="absolute"
                    top="0"
                    left="0"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Box size="50px" as={everythingHasBeenPayed ? BiHappy : FiDivideCircle} />
                    <Header as="h4" size="md" height="13%" mt={0}>
                        {everythingHasBeenPayed ? `Alla produkter har betalats. ` : `Notan är delad...`}
                    </Header>
                </Flex>
            )}
            <Header as="h4" size="xl" mb={6}>
                Antal:
                <Text fontSize="1rem"> (max 50 notor)</Text>
            </Header>
            <Keypad
                isDefaultOpen
                closeOnOutsideClick={false}
                customHandlers={{
                    Dela: () => splitByNumber(+numberOfReceipts >= 50 ? 50 : +numberOfReceipts)
                }}
                onChange={(number: any) => setNumberOfReceipts(number)}
                value={numberOfReceipts}
                disabledButtons={+numberOfReceipts === 0 ? ["Dela"] : []}
            >
                <InputGroup w="100%" size="xl" mb={2}>
                    <KeypadInput fullWidth />
                    <InputRightElement p={2}>
                        <Button h="100%" themeColor="red" onClick={() => setNumberOfReceipts("")}>
                            C
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <KeypadButtons height={`${28 * zoomFactor}vh`}>
                    {amountButtons.map(button => (
                        <KeypadButton key={button.value} {...button} />
                    ))}
                </KeypadButtons>
            </Keypad>
        </Flex>
    );
};
