import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { delay } from "lodash";

import { Box, Button, Flex, Header, Spinner } from "Atoms";
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "Organisms";
import {
    useAwaitModalResponse,
    usePos,
    useQopla,
    useSplitCashRegister,
    useDiscounts,
    createNewOrderWindow,
    generateOrderWindowName,
    getVisibleOrderWindows
} from "Providers";
import { CREATE_BONG_FROM_ORDERS } from "GraphQLMutations";
import { successNotification, errorNotification, confirmNotification } from "TempUtils";
import { SplitModeContent, SplitByNumber, SplitByAmount } from "./components";
import { paymentTabStatusTypes, POS_MODE, SPLIT_TYPE } from "Constants";
import { roundToTwo } from "NumberUtils";
import { CartProduct, GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY, OrderWindow } from "Types";
import { PuckModal } from "../PuckModal/PuckModal";
import { useInternalPos } from "..";
import { useTableStore } from "Stores";
import { calculateTotalPrice, gaProductionEvent } from "Utils";
import { useEffectOnce } from "Hooks";

type Props = {
    onExitTableMode: () => void;
};

export const SplitMode: React.FC<Props> = ({ onExitTableMode }) => {
    const {
        splitCartProducts,
        addSplitModeWindow,
        addCartProductToPay,
        addCartProductToPayByNumber,
        addCartProductToPayByAmount,
        leaveSplitMode,
        addAllCartProductsToPay,
        removeAllCartProductsToPay,
        handleSetSplitMode,
        handlePaymentStarted,
        getSplitOrderWindowIds,
        splitOrderIds,
        splitOrderNo,
        fromOrderWindowId,
        to: toOrderWindowIds,
        setSplit,
        isPaymentStarted,
        splitMode
    } = useSplitCashRegister();
    const {
        paymentTabsUtils: { clearOrderWindows, getActivePaymentTab },
        getOrderWindow,
        useOrderWindowsStore,
        handleSetActiveOrderWindowId,
        upsertOrderWindows,
        posZoomLevel
    } = usePos();
    const { orderWindows, split } = useOrderWindowsStore();
    const { selectedShop, selectedPos } = useQopla();
    const id = selectedShop?.id;
    const awaitConfirm = useAwaitModalResponse();
    const {
        combinedDiscount: { hasComboDiscounts },
        potentialUpsell: { onSplitUpsoldDiscounts }
    } = useDiscounts();
    const { settings } = useInternalPos();
    const tableService = useTableStore();

    const [createBongFromOrders, { loading }] = useMutation(CREATE_BONG_FROM_ORDERS);

    const isParkedOrderWindow = getOrderWindow(fromOrderWindowId)?.postponePayment;

    const hasNoAvailableLeft = !splitCartProducts.available.length;
    const everythingHasBeenPayed = !splitCartProducts.toPay.length && hasNoAvailableLeft;

    useEffect(() => {
        onSplitUpsoldDiscounts(fromOrderWindowId!, toOrderWindowIds[0]);
    }, [hasComboDiscounts === true, fromOrderWindowId]);

    useEffectOnce(() => {
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_CHECKOUT,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
            label: "Open split payment mode"
        });
    });

    const handleCreateBongFromOrders = async () => {
        const fromOrderWindow = getOrderWindow(fromOrderWindowId);
        const shouldOpenPuckModal = everythingHasBeenPayed && selectedPos?.puckEnabled && !fromOrderWindow.puckNo;

        let puckNo = fromOrderWindow.puckNo;

        if (shouldOpenPuckModal) {
            // @ts-ignore
            const { cancel, value } = await awaitConfirm({
                component: PuckModal,
                catchOnCancel: true,
                modalContent: {
                    puckNo: fromOrderWindow.puckNo,
                    preferredPuckTab: settings.preferredPuckTab
                }
            });

            if (!cancel && value) {
                puckNo = String(value);
            }
        }

        const { data } = await createBongFromOrders({
            variables: {
                orderIds: splitOrderIds,
                shopId: id,
                splitType: splitMode,
                puckNo
            }
        });

        if (data && data.createBongFromOrders) {
            successNotification("Bong skickad till köket", 1400);
            delay(() => {
                leaveSplitMode(true);
                if (tableService.selectedTable) {
                    onExitTableMode();
                }
            }, 1500);
        } else {
            errorNotification("Något gick snett", "Försök igen", 1400);
            if (tableService.selectedTable) {
                onExitTableMode();
            }
        }
    };

    const onLeaveSplitMode = async () => {
        const isSplitPaymentStarted = !!splitOrderNo;
        const isSplitPaymentFinished = everythingHasBeenPayed;
        if (isSplitPaymentStarted && !isSplitPaymentFinished) {
            const { value } = await confirmNotification(
                "Avsluta Dela notan?",
                "De följande köpen kommer inte packas ihop. Notan kommer avslutas utan att ha skickat en bong till köket",
                "Stäng Dela notan"
            );

            if (value) {
                leaveSplitMode(isSplitPaymentFinished);
            }
        } else {
            leaveSplitMode(isSplitPaymentFinished);
        }

        if (tableService.selectedTable) {
            onExitTableMode();
        }
    };

    const splitByNumber = (quantity: number) => {
        const originalOrderWindowIdx = orderWindows.findIndex((ow: OrderWindow) => ow.id === fromOrderWindowId);
        const totalCartPrice = calculateTotalPrice(
            orderWindows[originalOrderWindowIdx].cartProducts?.map((p: any) => p.orderProduct)
        );
        let cartProducts = splitCartProducts.available.map((order: any, idx: number) => {
            const updatedNetPrice = roundToTwo(order.orderProduct.totalNetPrice / quantity);
            const updatedTotalPrice = roundToTwo(order.orderProduct.totalPrice / quantity);
            const updatedUnitPrice = roundToTwo(order.orderProduct.unitPrice / quantity);

            return {
                ...order,
                orderProduct: {
                    ...order.orderProduct,
                    totalNetPrice: updatedNetPrice,
                    totalPrice: updatedTotalPrice,
                    unitPrice: updatedUnitPrice
                }
            };
        });

        const newTotalCartPrice = calculateTotalPrice(cartProducts.map((p: any) => p.orderProduct));
        let roundingError = 0;
        if (totalCartPrice !== roundToTwo(newTotalCartPrice * quantity)) {
            roundingError = roundToTwo(totalCartPrice - roundToTwo(newTotalCartPrice * quantity));
        }

        if (roundingError === 0) {
            createSplitOrderWindows(quantity, cartProducts);
        } else {
            const updatedCartProducts = cartProducts.map((order: any, idx: number) => {
                if (idx === 0) {
                    const updatedTotalPrice = roundToTwo(order.orderProduct.totalPrice + roundingError);
                    const updatedUnitPrice = roundToTwo(
                        order.orderProduct.unitPrice + roundingError / order.orderProduct.quantity
                    );
                    return {
                        ...order,
                        orderProduct: {
                            ...order.orderProduct,
                            unitPrice: updatedUnitPrice,
                            totalPrice: updatedTotalPrice
                        }
                    };
                } else {
                    return order;
                }
            });

            createSplitOrderWindows(quantity, cartProducts, updatedCartProducts);
        }
        addCartProductToPayByNumber(quantity);
    };

    const splitByAmount = (amount: number, totalToPay: number | null) => {
        const partOfTotal = totalToPay !== null ? amount / totalToPay : 1;

        const toPayCartProducts = getCartProductsWithUpdatedPrices(splitCartProducts.available, partOfTotal, amount);
        const restToPay = totalToPay !== null ? totalToPay - amount : amount;

        const availableCartProducts =
            partOfTotal != 1
                ? getCartProductsWithUpdatedPrices(splitCartProducts.available, 1 - partOfTotal, restToPay)
                : [];
        createSplitOrderWindows(1, toPayCartProducts);
        addCartProductToPayByAmount(toPayCartProducts, availableCartProducts);
    };

    const createSplitOrderWindows = (
        quantity: number,
        cartProducts: CartProduct[],
        cartProductsWithCorrectedPrices?: CartProduct[]
    ) => {
        const sourceOrderWindow = getOrderWindow(fromOrderWindowId);

        const newOrderWindows: OrderWindow[] = [];
        for (let index = 0; index < quantity; index++) {
            const newOrderWindow = createNewOrderWindow(
                // shopId
                id!,
                orderWindows,
                sourceOrderWindow.takeAway ? sourceOrderWindow.takeAway : selectedPos?.preferTakeAway,
                sourceOrderWindow,
                sourceOrderWindow.tableId
            );
            const updatedDisplayName = generateOrderWindowName(
                [
                    ...getVisibleOrderWindows(orderWindows, sourceOrderWindow?.tableId, getSplitOrderWindowIds()),
                    ...newOrderWindows
                ],
                "Del"
            );

            const shouldUseCartWithCorrectedPrices = !!cartProductsWithCorrectedPrices && index === quantity - 1;

            newOrderWindows.push({
                ...newOrderWindow,
                displayName: updatedDisplayName,
                cartProducts: (shouldUseCartWithCorrectedPrices ? cartProductsWithCorrectedPrices : cartProducts) ?? []
            });
        }

        const newOrderWindowIds = newOrderWindows.map(orderWindow => orderWindow.id);
        setSplit({
            mode: POS_MODE.SPLIT,
            fromOrderWindowId: sourceOrderWindow.id,
            to: [...toOrderWindowIds, ...newOrderWindowIds]
        });
        const updatedOrderWindows = [...orderWindows, ...newOrderWindows];
        handleSetActiveOrderWindowId(newOrderWindows[newOrderWindows.length - 1].id);
        upsertOrderWindows(updatedOrderWindows);
    };

    const getCartProductsWithUpdatedPrices = (
        cartProducts: CartProduct[],
        priceFactor: number,
        amountToPay: number
    ): CartProduct[] => {
        let currentTotal = 0;
        return cartProducts.map((cartProduct, idx) => {
            let updatedTotalPrice = roundToTwo(cartProduct.orderProduct.totalPrice * priceFactor);
            currentTotal = currentTotal + updatedTotalPrice;
            if (idx === cartProducts.length - 1) {
                const restToPay = roundToTwo(amountToPay - currentTotal);
                updatedTotalPrice = restToPay !== 0 ? roundToTwo(updatedTotalPrice + restToPay) : updatedTotalPrice;
            }
            const updatedNetPrice = roundToTwo(updatedTotalPrice / (1 + cartProduct.orderProduct.vatRate / 100));
            const updatedUnitPrice = updatedTotalPrice / cartProduct.orderProduct.quantity;
            return {
                ...cartProduct,
                orderProduct: {
                    ...cartProduct.orderProduct,
                    totalNetPrice: updatedNetPrice,
                    totalPrice: updatedTotalPrice,
                    unitPrice: updatedUnitPrice
                }
            };
        });
    };

    const activePaymentTab = getActivePaymentTab(toOrderWindowIds);
    const isOrderWindowBusy = !!activePaymentTab && paymentTabStatusTypes.DT_ONGOING !== activePaymentTab.status;

    const color = "gray.300";
    const borderRadiusSize = "lg";
    const borderWidth = "1px";
    const tabBorders = {
        borderColor: color,
        borderWidth: borderWidth,
        borderBottomWidth: borderWidth,
        borderLeftWidth: borderWidth,
        borderRightWidth: borderWidth,
        borderBottomLeftRadius: borderRadiusSize,
        borderBottomRightRadius: borderRadiusSize,
        borderStyle: "solid"
    };

    const zoomFactor = 2 - posZoomLevel / 100;

    const splitModeTabIndex = splitMode === SPLIT_TYPE.DISHES ? 0 : splitMode === SPLIT_TYPE.NUMBER ? 1 : 2;

    return (
        <Box bg="gray.200">
            <Flex
                h="auto"
                width={{ base: "85%", xl: "60%" }}
                maxWidth="53rem"
                maxH={posZoomLevel < 100 ? `${80 * zoomFactor}vh` : "80vh"}
                mx="auto"
                mt={16}
                align="center"
                justify="flex-start"
                direction="column"
                bg="white"
                rounded="md"
            >
                <Box mb={4} as="header" my={5}>
                    <Header>Dela notan</Header>
                </Box>
                <Tabs
                    index={splitModeTabIndex}
                    pb={5}
                    width="100%"
                    px={5}
                    minHeight={`${50 * zoomFactor}vh`}
                    variant="enclosed"
                    themeColor="black"
                >
                    <TabList borderColor={color} height="auto">
                        <Tab
                            key="byProdukt"
                            isDisabled={
                                splitMode !== SPLIT_TYPE.DISHES
                                    ? splitCartProducts.toPay.length !== 0 || isPaymentStarted
                                    : false
                            }
                            onClick={() => {
                                if (splitMode !== SPLIT_TYPE.DISHES) {
                                    handleSetSplitMode(SPLIT_TYPE.DISHES);
                                    addSplitModeWindow(getOrderWindow(fromOrderWindowId));
                                }
                            }}
                            fontSize="1.2rem"
                            borderBottom={splitMode === SPLIT_TYPE.DISHES ? "white 2px solid" : "#E2E8F0 1px solid"}
                            fontWeight={splitMode === SPLIT_TYPE.DISHES ? "600" : "500"}
                            py={5}
                            px={8}
                        >
                            Produkter
                        </Tab>
                        <Tab
                            key="byNumber"
                            isDisabled={
                                splitMode !== SPLIT_TYPE.NUMBER
                                    ? splitCartProducts.toPay.length !== 0 || isPaymentStarted
                                    : false
                            }
                            onClick={() => {
                                if (splitMode !== SPLIT_TYPE.NUMBER) {
                                    handleSetSplitMode(SPLIT_TYPE.NUMBER);
                                    clearOrderWindows(toOrderWindowIds, fromOrderWindowId);
                                    setSplit({
                                        ...split,
                                        to: []
                                    });
                                }
                            }}
                            fontSize="1.2rem"
                            fontWeight={splitMode === SPLIT_TYPE.NUMBER ? "600" : "500"}
                            borderBottom={splitMode === SPLIT_TYPE.NUMBER ? "white 2px solid" : "#E2E8F0 1px solid"}
                            p={5}
                            px={8}
                        >
                            Dela antal
                        </Tab>
                        <Tab
                            key="byAmount"
                            isDisabled={
                                splitMode !== SPLIT_TYPE.AMOUNT
                                    ? splitCartProducts.toPay.length !== 0 || isPaymentStarted
                                    : false
                            }
                            onClick={() => {
                                if (splitMode !== SPLIT_TYPE.AMOUNT) {
                                    handleSetSplitMode(SPLIT_TYPE.AMOUNT);
                                    clearOrderWindows(toOrderWindowIds, fromOrderWindowId);
                                    setSplit({
                                        ...split,
                                        to: []
                                    });
                                }
                            }}
                            fontSize="1.2rem"
                            fontWeight={splitMode === SPLIT_TYPE.AMOUNT ? "600" : "500"}
                            borderBottom={splitMode === SPLIT_TYPE.AMOUNT ? "white 2px solid" : "#E2E8F0 1px solid"}
                            p={5}
                            px={8}
                        >
                            Dela belopp
                        </Tab>
                    </TabList>
                    <TabPanels {...tabBorders}>
                        <TabPanel key="byProdukt">
                            <Flex
                                p={8}
                                bg="white"
                                direction="column"
                                rounded="md"
                                height={`${53 * zoomFactor}vh`}
                                minHeight={`${30 * zoomFactor}vh`}
                            >
                                <Box as="section" flex="1">
                                    {loading ? (
                                        <Flex justify="center">
                                            <Spinner size="large" />
                                        </Flex>
                                    ) : (
                                        <SplitModeContent
                                            splitCartProducts={splitCartProducts}
                                            addCartProductToPay={addCartProductToPay}
                                            isOrderWindowBusy={isOrderWindowBusy}
                                        />
                                    )}
                                </Box>
                            </Flex>
                        </TabPanel>
                        <TabPanel key="byNumber">
                            <SplitByNumber
                                everythingHasBeenPayed={everythingHasBeenPayed}
                                hasNoAvailableLeft={hasNoAvailableLeft}
                                splitByNumber={splitByNumber}
                                zoomFactor={zoomFactor}
                            />
                        </TabPanel>
                        <TabPanel key="byAmount">
                            <SplitByAmount
                                everythingHasBeenPayed={everythingHasBeenPayed}
                                hasNoAvailableLeft={hasNoAvailableLeft}
                                splitByAmount={splitByAmount}
                                zoomFactor={zoomFactor}
                                splitCartProducts={splitCartProducts}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box as="footer" display="flex" justifyContent="space-between" m={4} width="97%">
                    {splitMode === SPLIT_TYPE.DISHES && (
                        <Button
                            size="xl"
                            onClick={() =>
                                hasNoAvailableLeft ? removeAllCartProductsToPay() : addAllCartProductsToPay()
                            }
                            isDisabled={isOrderWindowBusy || everythingHasBeenPayed || loading}
                        >
                            {hasNoAvailableLeft ? "Lägg tillbaka alla" : "Välj alla"}
                        </Button>
                    )}
                    <Flex justifyContent="flex-end" width="100%">
                        <Button
                            size="xl"
                            themeColor="red"
                            mr={3}
                            onClick={onLeaveSplitMode}
                            isDisabled={
                                isOrderWindowBusy || loading || (!isParkedOrderWindow && everythingHasBeenPayed)
                            }
                        >
                            Avsluta
                        </Button>
                        <Button
                            size="xl"
                            themeColor="blue"
                            isDisabled={isOrderWindowBusy || !everythingHasBeenPayed || loading || isParkedOrderWindow}
                            onClick={handleCreateBongFromOrders}
                        >
                            Skicka bong
                        </Button>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};
