import React from "react";
import hash from "object-hash";

import { ADD_TO_REFUND_PRODUCTS, isDisabled } from "../components/ProductsList";
import { isVariablePriceType } from "ProductUtils";

/**
 * Selects all refundable products to refund
 *
 * @param {array} refundableProducts - array of products can be refunded
 * @param {array} hashedAlreadyRefundedProducts - array of products that has been refunded
 * @param {function} handleSetRefundOrders - function to set refund
 */
export const selectAllForRefund = (refundableProducts, hashedAlreadyRefundedProducts, handleSetRefundOrders) => {
    refundableProducts.forEach((prod, idx) => {
        if (prod.quantity > 0) {
            handleSetRefundOrders(prod, ADD_TO_REFUND_PRODUCTS, idx, true);
        }
    });
};

export const refundAllButtonDisabled = (refundableProducts, hashedProducts) => {
    return refundableProducts
        .filter(prod => {
            const { _, hasBeenRefunded } = isDisabled(true, hashedProducts, prod);
            const quantity = hasBeenRefunded ? prod.quantity - hasBeenRefunded.quantity : prod.quantity;
            return !(prod.quantity === 0 || quantity === 0);
        })
        .every(prod => prod.quantity === 0);
};

export const hashBundleOrderProduct = op => {
    return hash({
        id: op.refBundleProductId,
        selectedItems: op.selectedBundleProductItems,
        selectedModifications: op.modifications,
        selectedAddons: op.addons,
        comment: op.comment,
        priceType: op.priceType,
        weight: op.weight,
        ...(op.discountIds?.length > 0 && { discountIds: op.discountsIds })
    });
};

export const hashMenuProduct = (op, productIdx = 0) => {
    return hash({
        id: op.refProductId,
        selectedModifications: op.modifications,
        selectedAddons: op.addons,
        comment: op.comment,
        priceType: op.priceType,
        weight: op.weight,
        productIdx: op.weight > 0 ? productIdx : 0,
        ...(op.discountIds?.length > 0 && { discountIds: op.discountsIds })
    });
};

export const createAlreadyRefundedHash = searchOrderByReceiptNumber => {
    return searchOrderByReceiptNumber
        .flatMap(({ orderProducts }) => orderProducts)
        .reduce((acc, op, idx) => {
            const isBundleProduct = !!op?.refBundleProductId;
            const idToUse = isBundleProduct ? hashBundleOrderProduct(op) : hashMenuProduct(op, idx);
            if (acc[idToUse]) {
                acc[idToUse].quantity += op.quantity;
            } else {
                acc[idToUse] = { quantity: op.quantity };
            }
            return acc;
        }, {});
};

export const getModalHeader = (index, receiptNumber) => {
    if (index === 0) {
        return "Sök efter kvitton";
    } else if (index === 1) {
        return "Resultat";
    } else if (index === 2) {
        return `Produkter för kvittonr. ${receiptNumber}`;
    }
};

export const getActivePage = (transitions, pages) => {
    return transitions.map(({ item, props, key }) => {
        const Page = pages[item];
        return <Page key={key} style={props} />;
    });
};

export const formatNumber = str => (str.length > 0 ? parseInt(str) : null);

const getUpdatedSum = productsToRefund =>
    productsToRefund.reduce((acc, orderProduct) => {
        if (isVariablePriceType(orderProduct.priceType)) {
            acc += orderProduct.totalPrice * orderProduct.quantity;
        } else {
            acc += orderProduct.quantity * orderProduct.unitPrice;
        }
        return acc;
    }, 0);

/**
 * Sets which products that will be refunded
 *
 * @param {array} productsToRefund - array of products that will be refunded
 * @param {array} refundableProducts - array of products that are available to be refunded
 * @param {number} sum Total amount of refunded products
 * @param {object} orderProduct current order product to evaluate
 * @param {boolean} operation Decides if product will be added/removed to/from productsToRefund, true = add to refund, false = remove from refund
 * @param {boolean} refundAllQuantity select all quantities instead of single increment/decremenet
 * @returns {object} of the new productsToRefund and the updated sum
 */
export const updatedRefundProductsAndSum = (
    { productsToRefund, sum, refundableProducts },
    orderProduct,
    operation,
    refundAllQuantity = false,
    productIdx
) => {
    let updatedProductsToRefund = productsToRefund;
    let updatedSum = sum;
    const isBundleProduct = !!orderProduct.refBundleProductId;
    const hashedOrderProductId = isBundleProduct
        ? hashBundleOrderProduct(orderProduct)
        : hashMenuProduct(orderProduct, productIdx);

    refundableProducts.forEach((product, index) => {
        const hashedRefundableOrderProductId = product.refProductId
            ? hashMenuProduct(product, index)
            : hashBundleOrderProduct(product);
        const refundProduct = hashedOrderProductId === hashedRefundableOrderProductId;

        if (refundProduct) {
            const selectedProduct = productsToRefund.find(product => {
                const canBeMovedFromProductToRefund = operation ? true : product.quantity > 0;
                const areHashesMatched = isBundleProduct
                    ? hashBundleOrderProduct(product) === hashedRefundableOrderProductId
                    : hashMenuProduct(product, index) === hashedRefundableOrderProductId;
                return canBeMovedFromProductToRefund && areHashesMatched;
            });
            if (operation) {
                let quantityToRefund = refundAllQuantity
                    ? product.quantity + selectedProduct.quantity
                    : selectedProduct.quantity + 1;

                if (product.weight > 0) {
                    quantityToRefund = product.quantity;
                }
                updatedProductsToRefund[index] = {
                    ...product,
                    quantity: quantityToRefund
                };
                refundableProducts[index] = { ...product, quantity: refundAllQuantity ? 0 : product.quantity - 1 };
            } else {
                updatedProductsToRefund[index] = {
                    ...product,
                    quantity: selectedProduct.quantity - 1
                };
                refundableProducts[index] = { ...product, quantity: product.quantity + 1 };
            }
        }
        updatedSum = getUpdatedSum(updatedProductsToRefund);
    });

    return { updatedProductsToRefund, updatedSum };
};
