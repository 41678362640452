import React, { useState, useEffect } from "react";
import { BiHappy } from "@react-icons/all-files/bi/BiHappy";
import { FiDivideCircle } from "@react-icons/all-files/fi/FiDivideCircle";

import { Box, Button, Flex, Header, Keypad, KeypadInput, KeypadButtons, KeypadButton } from "Atoms";
import { InputGroup, InputRightElement } from "Molecules";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { CartProduct } from "Types";

type Props = {
    everythingHasBeenPayed: boolean;
    hasNoAvailableLeft: boolean;
    splitByAmount: (amount: number, totalToPay: number | null) => void;
    zoomFactor: number;
    splitCartProducts: { toPay: CartProduct[]; available: CartProduct[] };
};

export const SplitByAmount: React.FC<Props> = ({
    everythingHasBeenPayed,
    hasNoAvailableLeft,
    splitByAmount,
    zoomFactor,
    splitCartProducts
}) => {
    const [amount, setAmount] = useState("");

    const [totalToPay, setTotalToPay] = useState<null | number>(null);

    if (totalToPay === null) {
        const totalPrice = splitCartProducts.available.reduce((acc, curr) => acc + curr.orderProduct.totalPrice, 0);
        setTotalToPay(totalPrice);
    }

    useEffect(() => {
        const totalPrice = splitCartProducts.available.reduce((acc, curr) => acc + curr.orderProduct.totalPrice, 0);
        setTotalToPay(totalPrice);
    }, [splitCartProducts.available]);

    const amountButtons = [
        { value: "1", children: 1, disabled: totalToPay === +amount },
        { value: "2", children: 2, disabled: totalToPay === +amount },
        { value: "3", children: 3, disabled: totalToPay === +amount },
        { value: "4", children: 4, disabled: totalToPay === +amount },
        { value: "5", children: 5, disabled: totalToPay === +amount },
        { value: "6", children: 6, disabled: totalToPay === +amount },
        { value: "7", children: 7, disabled: totalToPay === +amount },
        { value: "8", children: 8, disabled: totalToPay === +amount },
        { value: "9", children: 9, disabled: totalToPay === +amount },
        {
            value: "Add",
            children: "Lägg till restande belopp",
            themeColor: "blue",
            fontSize: "1.2rem",
            disabled: totalToPay === +amount
        },
        { value: 0, children: 0, disabled: totalToPay === +amount },
        { value: "Spara", children: "Dela", themeColor: "green" }
    ];

    return (
        <Flex
            p={8}
            bg="white"
            direction="column"
            rounded="md"
            height={`${53 * zoomFactor}vh`}
            minHeight={`${30 * zoomFactor}vh`}
            position="relative"
        >
            {(everythingHasBeenPayed || hasNoAvailableLeft) && (
                <Flex
                    bg="rgb(255 255 255 / 90%)"
                    height="100%"
                    width="100%"
                    zIndex={10}
                    position="absolute"
                    top="0"
                    left="0"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Box size="50px" as={everythingHasBeenPayed ? BiHappy : FiDivideCircle} />
                    <Header as="h4" size="md" mt={4} height="13%">
                        {everythingHasBeenPayed ? `Alla produkter har betalats. ` : `Notan är delad...`}
                    </Header>
                </Flex>
            )}
            <Flex justifyContent="flex-start" alignItems="center" h="auto" pb={6}>
                <Header as="h4" size="xl" mb={0} mr={4}>
                    Kvar att betala:
                </Header>
                <Header as="h4" size="xl" mt={0}>
                    {formatSwedishFinancialNumbers(totalToPay)}
                </Header>
            </Flex>
            <Keypad
                isDefaultOpen
                closeOnOutsideClick={false}
                customHandlers={{
                    Spara: () => {
                        const updatedAmount = totalToPay && +amount > totalToPay ? totalToPay : +amount;
                        splitByAmount(updatedAmount, totalToPay);
                        setAmount("");
                    },
                    Add: () => {
                        setAmount(totalToPay!.toString());
                    }
                }}
                onChange={(number: any) => setAmount(number)}
                value={amount}
                disabledButtons={+amount === 0 || totalToPay === 0 ? ["Spara"] : []}
            >
                <InputGroup w="100%" size="xl" mb={2}>
                    <KeypadInput fullWidth />
                    <InputRightElement p={2}>
                        <Button h="100%" themeColor="red" onClick={() => setAmount("")}>
                            C
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <KeypadButtons height={`${30 * zoomFactor}vh`}>
                    {amountButtons.map(button => (
                        <KeypadButton key={button.value} {...button} />
                    ))}
                </KeypadButtons>
            </Keypad>
        </Flex>
    );
};
