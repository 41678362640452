import React, { PropsWithChildren } from "react";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";

import { Box, Flex, Header } from "Atoms";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { IconButton } from "Molecules";
import { getValidChildren } from "Utils";

type Props = {
    name: string;
    price: number;
    quantity: number;
    handler: Function;
} & PropsWithChildren;

export const PosReturnProductCard: React.FC<Props> = ({ name, price, quantity, handler, children }) => {
    const validChildren = getValidChildren(children);

    return (
        <Box
            width="100%"
            h="100%"
            borderColor="gray.400"
            borderWidth="2px"
            borderStyle="solid"
            borderRadius="lg"
            onClick={() => handler()}
        >
            <Flex align="center">
                <Flex direction="column" flex="1">
                    <Flex as="header" justify="space-between" align="center">
                        <Flex p={4}>
                            <Header size="sm" mb={0} mr={1}>
                                {quantity}x
                            </Header>
                            <Header size="sm" m={0}>
                                {name}
                            </Header>
                        </Flex>
                        <Flex align="center">
                            <Header size="sm" m={0} mr={4}>
                                {formatSwedishFinancialNumbers(price)}
                            </Header>
                            <IconButton icon={FiPlus} size="lg" />
                        </Flex>
                    </Flex>
                    {!!validChildren.length && (
                        <Box as="section" flex="1" px={8} pb={4}>
                            {validChildren}
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};
