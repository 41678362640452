import React from "react";
import propTypes from "prop-types";

export const CartOrderProductComment = ({ comment }) => (
    <div className="pos-modification" style={{ display: "flex", flexDirection: "column" }}>
        <span>
            <strong>Kommentar:</strong>
            <p style={{ marginLeft: "5px", whiteSpace: "pre-line" }}>{comment}</p>
        </span>
    </div>
);

CartOrderProductComment.propTypes = {
    comment: propTypes.string
};
