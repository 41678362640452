import React from "react";
import { List } from "semantic-ui-react";
import { formatSwedishFinancialNumbers, SpringButton } from "TempUtils";
import "../index.scss";
import { hashBundleOrderProduct, hashMenuProduct } from "../utils/receiptModalUtils";
import { AddonsCartItem } from "../../../../../poses/shared/components";
import { isVariablePriceType } from "ProductUtils";
import { getUnitStringForPriceType } from "PriceUtils";

export const ADD_TO_REFUND_PRODUCTS = true;
const ADD_TO_REFUNDABLE_PRODUCTS = false;

const generateLists = (refundableProducts, productsToRefund, handleSetRefundOrders) => {
    return [
        {
            products: refundableProducts,
            title: "Produkter som kan returneras",
            btnText: "Retur",
            action: (orderProduct, productIdx, operation = ADD_TO_REFUND_PRODUCTS) =>
                handleSetRefundOrders(orderProduct, operation, productIdx),
            style: { flex: 1, marginRight: "2.5rem" },
            isRefundableProduct: true
        },
        {
            products: productsToRefund,
            title: "Produkter som kommer returneras",
            btnText: "Ångra",
            action: (orderProduct, productIdx, operation = ADD_TO_REFUNDABLE_PRODUCTS) =>
                handleSetRefundOrders(orderProduct, operation, productIdx),
            style: { flex: 1 },
            isRefundableProduct: false
        }
    ];
};

export const isDisabled = (isRefundableProduct, refundedHashedProducts, product) => {
    let hasBeenRefunded;
    if (isRefundableProduct) {
        const idToUse = product.refProductId ? hashMenuProduct(product) : hashBundleOrderProduct(product);
        hasBeenRefunded = refundedHashedProducts[idToUse];

        if (hasBeenRefunded) {
            return {
                disabled: product.quantity <= 0,
                hasBeenRefunded
            };
        }
    }
    return { disabled: product.quantity === 0, hasBeenRefunded };
};

export const ProductsList = ({
    refundableProducts,
    productsToRefund,
    refundedHashedProducts,
    handleSetRefundOrders
}) => {
    const lists = generateLists(refundableProducts, productsToRefund, handleSetRefundOrders);
    let isFullyRefunded = 0;

    return (
        <div className="products-list-container">
            {lists.map((list, i) => (
                <div key={i} style={list.style}>
                    <h3>{list.title}</h3>
                    <List size="huge" relaxed verticalAlign="middle">
                        {list.products.map((orderProduct, j) => {
                            const hasVariablePriceType = isVariablePriceType(orderProduct.priceType);
                            const unitString = getUnitStringForPriceType(orderProduct.priceType);

                            const { disabled, _ } = isDisabled(
                                list.isRefundableProduct,
                                refundedHashedProducts,
                                orderProduct
                            );

                            isFullyRefunded += orderProduct.quantity;
                            if (orderProduct.quantity === 0) {
                                return null;
                            }
                            return (
                                <List.Item key={orderProduct.name + j} className="product-list-item">
                                    <List.Content floated="left">
                                        {hasVariablePriceType ? (
                                            <p style={{ marginBottom: 0 }}>
                                                {orderProduct.weight} {unitString} x {orderProduct.name}
                                            </p>
                                        ) : (
                                            <p style={{ marginBottom: 0 }}>
                                                {orderProduct.quantity}x {orderProduct.name}
                                            </p>
                                        )}
                                        {orderProduct.selectedBundleProductItems && (
                                            <ul style={{ margin: 0 }}>
                                                {orderProduct.selectedBundleProductItems.map((selectedItem, k) => (
                                                    <React.Fragment key={i + orderProduct.refBundleProductId + k}>
                                                        <li
                                                            style={{
                                                                paddingLeft: "0.3em",
                                                                color: "grey",
                                                                fontSize: "0.7em",
                                                                listStyleType: "none"
                                                            }}
                                                        >
                                                            {selectedItem.name}
                                                        </li>
                                                        {selectedItem.addons && (
                                                            <AddonsCartItem hidePrice addons={selectedItem.addons} />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        )}
                                        {orderProduct.comment && (
                                            <p style={{ fontSize: "0.7em", marginBottom: "0.5em" }}>
                                                <strong>Kommentar:</strong> {orderProduct.comment}
                                            </p>
                                        )}
                                        {orderProduct.addons && (
                                            <AddonsCartItem hidePrice addons={orderProduct.addons} />
                                        )}
                                        <p>
                                            {formatSwedishFinancialNumbers(
                                                hasVariablePriceType
                                                    ? orderProduct.totalPrice
                                                    : orderProduct.unitPrice * orderProduct.quantity
                                            )}
                                        </p>
                                    </List.Content>
                                    <List.Content floated="right">
                                        <SpringButton
                                            className="ui big basic button"
                                            disabled={disabled}
                                            customOnClick={() => list.action(orderProduct, j)}
                                        >
                                            {list.btnText}
                                        </SpringButton>
                                    </List.Content>
                                </List.Item>
                            );
                        })}
                    </List>
                    {list.isRefundableProduct && isFullyRefunded === 0 && (
                        <h4
                            style={{
                                fontWeight: "normal",
                                color: "4f4f4f",
                                marginBottom: "30px",
                                marginTop: "10px"
                            }}
                        >
                            - Alla produkter är returnerade -
                        </h4>
                    )}
                </div>
            ))}
        </div>
    );
};
